import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import shieldExclamation from 'core/svg/fa/shield-exclamation.svg';
import { t } from 'shared/translations';
export const showErrorApiResponseSnackbar = ({ header, body, error, persist = true }) => {
    if (error.status === 401) {
        enqueueSnackbar({
            variant: 'warning',
            icon: shieldExclamation,
            header: t('access.denied.label'),
            body: t('user.does.not.have.enough.access.rights'),
            persist,
        });
        return;
    }
    enqueueSnackbar({ variant: 'error', header, body: body ?? getErrorFromException(error), persist });
    console.error(`Api request failed.`, error);
};
