export const MuiListItem = {
    styleOverrides: {
        root: {
            maxHeight: '1.1rem',
            fontSize: '1rem',
            minWidth: '2rem',
        },
    },
};
export const MuiListItemIcon = {
    styleOverrides: {
        root: {
            maxHeight: '1.1rem',
        },
    },
};
export const MuiListItemText = {};
