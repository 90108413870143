import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ClearIconButton, TextFieldStyled, sxClearIcon, sxInputProps } from './CheckboxSelectRootButton.styled';
import { IconButton, useTheme } from '@mui/material';
import { SvgIcon } from 'core/ui';
import chevronDownIcon from 'core/svg/fa/chevron-down.svg';
import { t } from 'shared/translations';
import xmarkIcon from 'core/svg/fa/xmark.svg';
const CheckboxSelectRootButton = ({ selectedItemsCount, onClick, onClearButtonClick }) => {
    const { palette } = useTheme();
    const handleClearButtonClick = (event) => {
        event.stopPropagation();
        onClearButtonClick();
    };
    return (_jsx(TextFieldStyled, { onClick: onClick, value: selectedItemsCount ? `${t('selected.label')}: ${selectedItemsCount}` : '', inputProps: {
            type: 'button',
            readOnly: true,
            sx: sxInputProps,
        }, endAdornment: _jsxs(_Fragment, { children: [Boolean(selectedItemsCount) && (_jsx(ClearIconButton, { onClick: handleClearButtonClick, children: _jsx(SvgIcon, { icon: xmarkIcon, size: "xs", color: palette.common.black, sx: sxClearIcon }) })), _jsx(IconButton, { disableRipple: true, children: _jsx(SvgIcon, { icon: chevronDownIcon, size: "3xs", color: palette.common.black }) })] }) }));
};
export { CheckboxSelectRootButton };
