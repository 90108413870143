import { useSessionStorageBase } from './useSessionStorageBase';
/**
 * Hook that allows to use *sessionStorage* without reference to any **application**.
 *
 * @template T - The type of the state to be stored in session storage.
 * @template K - The type of the key.
 * @param {K} key - The key under which the value will be stored in session storage.
 * @param {T | (() => T)} initialValue - The initial value of the state or a function that returns the initial value.
 * @param {?UseSessionStorageOptions<T>} [options] - Options for customizing the behavior of serialization and deserialization (optional).
 * @returns {[T, Dispatch<SetStateAction<T>>, () => void]} A tuple containing the stored value, a function to set the value and a function to remove the key from storage.
 * @example
 * ```tsx
 * // Access the `count` value, the `setCount` function to update it and `removeCount` function to remove the key from storage.
 * const [count, setCount, removeCount] = useCoreSessionStorage('count', 0);
 * // Acts like a native useState from react.
 * setCount(prevValue => prevValue + 1)
 * ```
 */
export const useCoreSessionStorage = (key, initialValue, options) => useSessionStorageBase(key, initialValue, options);
