import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogContent } from '@mui/material';
import { CircularProgressBackdrop } from '../CircularProgressBackdrop';
import { DialogContentContainer } from './DialogContentBackdrop.styled';
import { Spinner } from 'shared/components';
/**
 * Use {@link DialogContentBackdrop} instead of MUI native {@link DialogContent} component to block the dialog paper with a backdrop
 * while the form is submitting and show {@link CircularProgressBackdrop} in center of the dialog.
 *
 * Also, spinner is show on data fetching.
 * @example
 * ```tsx
 *  <DialogContentBackdrop isSubmitting={isSubmitting}>
 *    <FormControl>...</FormControl>
 * 		// Other controls...
 *    <FormControl>...</FormControl>
 *  </DialogContentBackdrop>
 * ```
 *
 * @param props
 * @returns
 */
const DialogContentBackdrop = ({ isSubmitting, isFetching, children, ...dialogContentProps }) => {
    return (_jsxs(DialogContentContainer, { children: [_jsx(DialogContent, { ...dialogContentProps, children: isFetching ? _jsx(Spinner, { fullHeight: true }) : children }), _jsx(CircularProgressBackdrop, { dialog: true, open: isSubmitting })] }));
};
export { DialogContentBackdrop };
