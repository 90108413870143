// TODO: check if we can use following getEnumKeysAsArray function instead.
export const getEnumValuesAsArray = (en) => {
    return (Object.keys(en)
        //.map((key) => Number(key))
        .map(Number)
        .filter((key) => !Number.isNaN(key)));
};
// TODO: check my own variant, mb it is better?
export const getEnumValuesAsArray2 = (en) => {
    return Object.values(en).filter((value) => typeof value === 'number');
};
/**
 * Gets a keys of the specified enum.
 * @param en - enum to extract keys
 * @returns An array with keys (names).
 * @example
 * {getEnumKeysAsArray(TransferProtocol).map((key) => (
                <MenuItem key={TransferProtocol[key as any]} value={TransferProtocol[key as any]}>
                    {key.toLowerCase()}
                </MenuItem>
        ))}
 */
export const getEnumKeysAsArray = (en) => {
    return Object.keys(en).filter((key) => !Number.isNaN(Number(en[key])));
};
/**
 * Gets the array of { key: string, value: number } objects.
 * @param en - enum to convert to an array of objects.
 * @returns An array of objects with { key: string, value: number } pair.
 * @example
 * {getEnumAsKeyValuePair(TransferProtocol).map(({ key, value }) => (
                <MenuItem key={value} value={value}>
                    {key.toLowerCase()}
                </MenuItem>
        ))}
 */
export const getEnumAsKeyValuePair = (en) => {
    return Object.keys(en)
        .filter((key) => !Number.isNaN(Number(en[key])))
        .map((key) => ({ key, value: en[key] }));
};
