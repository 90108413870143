import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, Tooltip, styled } from '@mui/material';
import { forwardRef, useContext, useEffect } from 'react';
import { ActionIconsContext } from '../ActionIcons.context';
import { Link as RouterLink } from 'react-router-dom';
import { SvgIcon } from 'core/ui';
export const ActionIconTemplate = forwardRef(({ icon, size, title, disabled, color, defaultTitle, route, onClick, ...props }, ref) => {
    const containerContext = useContext(ActionIconsContext);
    useEffect(() => {
        if (!containerContext) {
            throw new Error('ActionIcon must be used as a child of ActionIcons component.');
        }
    });
    const { disabled: containerDisabled, color: containerColor, iconSize } = containerContext ?? {};
    const routeOrOnClickProps = route ? { component: RouterLink, to: route } : onClick ? { onClick } : {};
    return (_jsx(Tooltip, { title: title ?? defaultTitle, enterDelay: 600, children: _jsx(IconButtonStyled, { tabIndex: -1, ref: ref, ...props, ...routeOrOnClickProps, disabled: disabled ?? containerDisabled, onClick: onClick, customColor: color ?? containerColor, children: _jsx(SvgIcon, { icon: icon, size: size ?? iconSize }) }) }));
});
export const IconButtonStyled = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'customColor' })(({ theme, customColor }) => ({
    color: customColor ?? theme.palette.primary.dark,
    '&.Mui-disabled': {
        color: theme.palette.primary.dark,
        opacity: 0.4,
    },
}));
