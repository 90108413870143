import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItemIcon, ListItemText, MenuItem, Select as MuiSelect } from '@mui/material';
import { StockType } from 'shared/modules/stock/models';
import { SvgIcon } from 'core/ui';
import circleDotIcon from 'core/svg/fa/circle-dot.svg';
import { getStockTypeColor } from 'admin/helpers';
import { sxMuiSelectInputProps } from './StorageSectorTypeSelect.styled';
import { t } from 'shared/translations';
const StorageSectorTypeSelect = ({ value, disabled, onChange }) => {
    return (_jsxs(MuiSelect, { value: value || '', disabled: disabled, onChange: (event) => {
            if (!(event.target.value === typeof 'string'))
                onChange(event.target.value);
        }, inputProps: { sx: sxMuiSelectInputProps }, children: [_jsxs(MenuItem, { value: StockType.Stock, children: [_jsx(ListItemIcon, { sx: (theme) => ({ paddingLeft: '.125rem', color: getStockTypeColor(StockType.Stock, theme).main }), children: _jsx(SvgIcon, { size: "sm", icon: circleDotIcon }) }), _jsx(ListItemText, { primary: t('stock.label') })] }), _jsxs(MenuItem, { value: StockType.Crossdocking, children: [_jsx(ListItemIcon, { sx: (theme) => ({ paddingLeft: '.125rem', color: getStockTypeColor(StockType.Crossdocking, theme).main }), children: _jsx(SvgIcon, { size: "sm", icon: circleDotIcon }) }), _jsx(ListItemText, { primary: t('crossdocking.label') })] })] }));
};
export { StorageSectorTypeSelect };
