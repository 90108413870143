import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from 'core/ui';
import { styled, useTheme } from '@mui/material';
import circleCheckIcon from 'core/svg/fa/circle-check.svg';
import circleInfoIcon from 'core/svg/fa/circle-info.svg';
import circleXmarkIcon from 'core/svg/fa/circle-xmark.svg';
import inboxInIcon from 'core/svg/fa/inbox-in.svg';
import lockIcon from 'core/svg/fa/lock.svg';
import { snackbarVariantIsDefault } from './snackbarVariantIsDefault';
import triangleExclamationIcon from 'core/svg/fa/triangle-exclamation.svg';
export const SnackbarIcon = ({ variant, icon: customIcon }) => {
    const { palette } = useTheme();
    const size = variant === 'receiptSubmissionSuccess' || variant === 'receiptBlockSuccess'
        ? '1.75rem'
        : variant === 'receiptSubmissionError'
            ? '2rem'
            : '4xl';
    const icon = customIcon
        ? customIcon
        : variant === 'receiptSubmissionSuccess'
            ? inboxInIcon
            : variant === 'receiptBlockSuccess'
                ? lockIcon
                : variant === 'success'
                    ? circleCheckIcon
                    : variant === 'warning'
                        ? triangleExclamationIcon
                        : variant === 'error' || variant === 'receiptSubmissionError'
                            ? circleXmarkIcon
                            : variant === 'info'
                                ? circleInfoIcon
                                : circleInfoIcon;
    const color = variant === 'success' || variant === 'receiptSubmissionSuccess'
        ? palette.success.main
        : variant === 'warning' || variant === 'receiptBlockSuccess'
            ? palette.warning.main
            : variant === 'error' || variant === 'receiptSubmissionError'
                ? palette.error.dark
                : variant === 'info'
                    ? palette.info.main
                    : palette.info.main;
    return (_jsx(IconContainer, { size: snackbarVariantIsDefault(variant) ? 'defaultSnackbarIconSize' : 'receiptSnackbarIconSize', children: _jsx(SvgIcon, { size: size, icon: icon, color: color }) }));
};
const IconContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'size' })(({ theme, size }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: size == 'defaultSnackbarIconSize' ? '1.5rem' : '2rem',
    height: size == 'defaultSnackbarIconSize' ? '1.5rem' : '2rem',
}));
