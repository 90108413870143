import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    shouldTriggerRangesValidation: false,
    rangesForSubmit: undefined,
};
const stockSettingsSlice = createSlice({
    name: 'stockSettings',
    initialState,
    reducers: {
        triggerRangesValidation: (state) => {
            state.shouldTriggerRangesValidation = true;
        },
        setRangesValidationDone: (state) => {
            state.shouldTriggerRangesValidation = false;
        },
        prepareRangesForSubmit: (state, action) => {
            const filteredFlat = action.payload.filter((cell) => cell.selectedBy !== null);
            const rangeIds = [...new Set(filteredFlat.map((el) => el.selectedBy))];
            const rangesForSubmit = [];
            rangeIds.forEach((rangeId) => {
                rangesForSubmit.push(filteredFlat
                    .filter((el) => el.selectedBy === rangeId)
                    .map((cell) => ({ stockCellId: cell.stockCellId, sectorName: cell.sectorName, sectorX: cell.sectorX, sectorY: cell.sectorY })));
            });
            state.rangesForSubmit = rangesForSubmit;
        },
        removeSubmittedRanges: (state) => {
            state.rangesForSubmit = undefined;
        },
    },
});
export const { actions: stockSettingsActions, reducer: stockSettingsStateReducer } = stockSettingsSlice;
