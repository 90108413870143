export const MuiSelect = {
    styleOverrides: {
        select: {
            height: '1.5rem',
        },
        icon: ({ ownerState, theme }) => ({
            color: theme.palette.grey[400],
        }),
    },
};
// MenuProps={{
// 	PaperProps: {
// 		sx: {
// 			borderRadius: '0.375rem',
// 			marginTop: '.25rem',
// 		},
// 	},
// 	MenuListProps: {
// 		sx: (theme) => ({
// 			padding: '.25rem 0',
// 			'.MuiMenuItem-root': {
// 				// Force MenuItem to be 36px in height.
// 				lineHeight: '1.429',
// 				'&.Mui-selected': {
// 					fontWeight: theme.typography.fontWeightMedium,
// 					backgroundColor: theme.palette.common.white,
// 				},
// 				':not(:hover):not(.Mui-focusVisible)&.Mui-selected': {
// 					'.MuiTypography-root': {
// 						fontWeight: theme.typography.fontWeightMedium,
// 					},
// 				},
// 				':hover, &.Mui-focusVisible': {
// 					// backgroundColor: `${theme.palette.primary.dark} !important`,
// 					backgroundColor: `${theme.palette.primary.lightest50} !important`,
// 					// TODO: check (added extra for no icon)
// 					// color: theme.palette.common.white,
// 					// '.MuiTypography-root': {
// 					// 	color: theme.palette.common.white,
// 					// },
// 				},
// 			},
// 		}),
// 	},
// }}
