import { getPeriodDateRangeValues } from 'core/ui';
import { formatDateForOrdersFilter } from 'admin/modules/orders/helpers';
const getDatesFromPeriodDateRageValue = (value) => {
    if (value.period === 'custom' && value.periodDateFrom !== null && value.periodDateTo !== null) {
        return {
            startDate: value.periodDateFrom,
            endDate: value.periodDateTo,
        };
    }
    const customPeriod = getPeriodDateRangeValues(value.period);
    return {
        startDate: customPeriod.periodDateFrom,
        endDate: customPeriod.periodDateTo,
    };
};
export const prepareForQuery = ({ startPrice, endPrice, startQty, endQty, periodDateRange, ...filter }) => {
    const range = getDatesFromPeriodDateRageValue(periodDateRange);
    const dates = {
        startDate: formatDateForOrdersFilter(range.startDate),
        endDate: formatDateForOrdersFilter(range.endDate),
    };
    const result = { ...filter, ...dates };
    // Fixing react-form-field not supported undefined, but nulls break query so props are excluded when nulls.
    if (startPrice)
        result.startPrice = startPrice;
    if (endPrice)
        result.endPrice = endPrice;
    if (startQty)
        result.startQty = startQty;
    if (endQty)
        result.endQty = endQty;
    return result;
};
