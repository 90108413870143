export const MuiLink = {
    defaultProps: {
        underline: 'none',
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            ':hover': {
                color: theme.palette.primary.main,
            },
        }),
    },
};
