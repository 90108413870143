import { IconButton, styled } from '@mui/material';
import { TextField } from '../TextField';
export const TextFieldStyled = styled(TextField)({
    paddingLeft: '.25rem',
    paddingRight: '.25rem',
    ':hover': {
        'button:nth-of-type(1)': {
            display: 'inline-flex',
        },
    },
});
export const ClearIconButton = styled(IconButton)({
    display: 'none',
    padding: '.35rem',
});
export const sxInputProps = {
    textAlign: 'left',
    ':hover': {
        cursor: 'pointer',
    },
};
export const sxClearIcon = { paddingTop: '1px' };
