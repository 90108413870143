import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '../SvgIcon';
import spinnerThirdIcon from 'core/svg/fa/spinner-third.svg';
import tireIcon from 'core/svg/fa/tire.svg';
import { useTheme } from '@mui/material';
const SpinnerSvgIcon = ({ size = 'xl', color, variant = 'spinner' }) => {
    const theme = useTheme();
    return (_jsx(SvgIcon, { icon: variant === 'spinner' ? spinnerThirdIcon : tireIcon, size: size, color: color ?? theme.palette.primary.dark, sx: {
            animation: 'fa-spin 2s infinite linear',
        } }));
};
export { SpinnerSvgIcon };
