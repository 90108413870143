import { MailingMode } from 'admin/modules/priceLists/models';
import { t } from 'shared/translations';
const getMailingModeName = (mailingMode) => {
    switch (mailingMode) {
        case MailingMode.Weekdays: {
            return t('on.weekdays');
        }
        case MailingMode.Daily: {
            return t('everyday.label');
        }
        case MailingMode.Manually: {
            return t('manually.label');
        }
        default: {
            throw new Error('Unexpected MailingMode value.');
        }
    }
};
export { getMailingModeName };
