import { createSlice } from '@reduxjs/toolkit';
const costHistoryPopoverOffsetLeft = 40;
const sharedStockInitialState = {
    costHistory: {
        open: false,
        data: undefined,
    },
    uploadPricesFileDialog: {
        open: false,
    },
    cellSelection: {
        // If cellId is undefined it means no grouping by cell selected.
        cellId: undefined,
        inactive: false,
        items: [],
    },
};
const createSharedStockSlice = ({ name = 'stock', initialState, reducers, }) => {
    return createSlice({
        name,
        initialState,
        // reducers: {
        //   start(state) {
        //     state.status = 'loading'
        //   },
        //   //  * If you want to write to values of the state that depend on the generic
        //   //  * (in this case: `state.data`, which is T), you might need to specify the
        //   //  * State type manually here, as it defaults to `Draft<GenericState<T>>`,
        //   //  * which can sometimes be problematic with yet-unresolved generics.
        //   //  * This is a general problem when working with immer's Draft type and generics.
        //   success(state: GenericState<T>, action: PayloadAction<T>) {
        //     state.data = action.payload
        //     state.status = 'finished'
        //   },
        //   ...reducers,
        // },
        reducers: {
            openCostHistoryPopover: (state, action) => {
                state.costHistory.open = true;
                state.costHistory.data = action.payload;
                state.costHistory.data.position.left -= costHistoryPopoverOffsetLeft;
            },
            closeCostHistoryPopover: (state) => {
                state.costHistory.open = false;
                state.costHistory.data = undefined;
            },
            openUploadPricesFileDialog: (state) => {
                state.uploadPricesFileDialog.open = true;
            },
            closeUploadPricesFileDialog: (state) => {
                state.uploadPricesFileDialog = initialState.uploadPricesFileDialog;
            },
            selectCellItem: (state, action) => {
                const { cellId, inactive, item } = action.payload;
                if (state.cellSelection.cellId == cellId && state.cellSelection.inactive === inactive) {
                    state.cellSelection.items.push(item);
                }
                else {
                    state.cellSelection = {
                        cellId,
                        inactive,
                        items: [item],
                    };
                }
            },
            unselectCellItem: (state, action) => {
                state.cellSelection.items = state.cellSelection.items.filter((el) => el.stockItemId !== action.payload.stockItemId);
            },
            selectAll: (state, action) => {
                const { cellId, inactive, allItemsSelection } = action.payload;
                state.cellSelection.cellId = cellId;
                state.cellSelection.inactive = inactive;
                state.cellSelection.items = [...allItemsSelection];
            },
            unselectAll: (state) => {
                state.cellSelection = initialState.cellSelection;
            },
            ...reducers,
        },
    });
};
export { sharedStockInitialState, createSharedStockSlice };
