import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel, MenuItem, Typography, styled } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { BlockReason } from 'shared/models';
import { Select } from 'core/ui';
import { getBlockReasonLabel } from 'warehouse/helpers';
import { getEnumValuesAsArray } from 'core/utils/convert';
import { t } from 'shared/translations';
const timeSpentOnFocus = 10;
const blockReasonIds = getEnumValuesAsArray(BlockReason);
/** Currently used in **Warehouse** app only. */
const BlockReasonSelect = ({ ownerRef, focusRef, disabled, ...useControllerProps }) => {
    const { field: { ref, ...field }, fieldState: { invalid, error }, } = useController(useControllerProps);
    const handleOwnerKeyDown = useCallback((event) => {
        // Numpad - digits from numpad part of keyboard
        const targetDigitKeyCodes = [...blockReasonIds.map((id) => `Digit${id}`), ...blockReasonIds.map((id) => `Numpad${id}`)];
        if (event.shiftKey && targetDigitKeyCodes.includes(event.code)) {
            const reasonId = Number(event.code.slice(-1));
            field.onChange(reasonId);
            focusRef?.current?.focus();
            setTimeout(() => focusRef?.current?.select?.(), timeSpentOnFocus);
            return;
        }
    }, [field, focusRef]);
    useEffect(() => {
        // Handling eslint error:
        // The ref value 'ownerRef.current' will likely have changed by the time this effect cleanup function runs. If this ref points to a node rendered by React, copy 'ownerRef.current' to a variable inside the effect, and use that variable in the cleanup function. eslint react-hooks/exhaustive-deps
        const ownerElement = ownerRef.current;
        ownerElement?.addEventListener('keydown', handleOwnerKeyDown);
        return () => {
            ownerElement?.removeEventListener('keydown', handleOwnerKeyDown);
        };
    }, [handleOwnerKeyDown, ownerRef]);
    return (_jsxs(BlockReasonFormControl, { error: invalid, disabled: disabled, children: [_jsx(FormLabel, { children: t('reason.label') }), _jsx(Select, { ...field, autoFocus: true, children: blockReasonIds.map((reason) => (_jsx(MenuItem, { value: reason, children: getBlockReasonLabel(reason) }, reason))) }), _jsx(FormHelperText, { component: "span", children: error?.message ?? _jsx(Typography, { fontSize: "inherit", color: ({ palette }) => palette.text.disabled, children: `Shift + 1/2/3/4/5/6` }) })] }));
};
const BlockReasonFormControl = styled(FormControl)({ marginBottom: '.5rem' });
export { BlockReasonSelect };
