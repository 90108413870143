import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ContentText, IconContainer, TextInfoContainer, TitleText, sxDialogContent } from './ConfirmationDialog.styled';
import { SvgIcon } from 'core/ui';
import questionCircleIcon from 'core/svg/fa/circle-question.svg';
import { t } from 'shared/translations';
const ConfirmationDialog = ({ onConfirm, onCancel, isOpen, maxWidth, title, contentText, confirmButtonLabel, confirmButtonColor, cancelButtonLabel, }) => {
    return (_jsxs(Dialog, { open: isOpen, maxWidth: maxWidth === undefined ? 'sm' : maxWidth, fullWidth: true, children: [_jsx(DialogContent, { sx: sxDialogContent, children: _jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(IconContainer, { children: _jsx(SvgIcon, { icon: questionCircleIcon, size: "2rem" }) }), _jsxs(TextInfoContainer, { children: [title && _jsx(TitleText, { variant: "h6", children: title }), _jsx(Box, { mt: 1, children: _jsx(ContentText, { children: contentText }) })] })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { variant: "outlined", onClick: onCancel, children: cancelButtonLabel || t('cancel.label') }), _jsx(Button, { variant: "contained", color: confirmButtonColor || 'primary', onClick: onConfirm, children: confirmButtonLabel || t('save.label') })] })] }));
};
export { ConfirmationDialog };
