import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { decodeToken } from './decodeToken';
import { getApiUrl } from 'shared/helpers/environmentHelper';
export const authApi = createApi({
    reducerPath: 'api/auth',
    baseQuery: fetchBaseQuery({
        baseUrl: `${getApiUrl()}/api/`,
        //credentials: 'include', // This allows server to set cookies
        //credentials: 'same-origin',
        //mode: 'no-cors',
        //mode: 'cors',
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => {
                return {
                    url: 'Account/sign-in',
                    method: 'POST',
                    body: credentials,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: { error, token }, } = await queryFulfilled;
                    if (error || !token) {
                        console.log('login(), error: ', error);
                        console.log('login(), token: ', token);
                        return;
                    }
                    //dispatch(setUser(data));
                    localStorage.setItem('token', token);
                    // TODO: to delete
                    //const user = jwt(token) as string;
                    //console.log('user: ', user);
                    //dispatch(authSuccess({ token, user }));
                }
                catch (error) {
                    console.error('api/auth/login error:', error);
                }
            },
        }),
    }),
});
/**
 * This separate fetch method is required since right after token received it is not yet existed in store state and local storage.
 * That is why it is used manually here.
 * */
// export const getUserDataByTokenOld = async (token: string): Promise<IAuthUserData & IAuthUserAccessData> => {
// 	const { expiration, userId } = decodeToken(token);
// 	const userRequest = fetch(`${getApiUrl()}/api/user/getUser/${userId}`, {
// 		method: 'GET',
// 		cache: 'no-cache',
// 		headers: {
// 			// 'Content-Type': 'application/json',
// 			authorization: `Bearer ${token}`,
// 		},
// 	});
// 	const roleRequest = fetch(`${getApiUrl()}/api/roles/getRoles`, {
// 		method: 'GET',
// 		cache: 'no-cache',
// 		headers: {
// 			// 'Content-Type': 'application/json',
// 			authorization: `Bearer ${token}`,
// 		},
// 	});
// 	const [userResponse, roleResponse] = await Promise.all([userRequest, roleRequest]);
// 	const user: IUser = await userResponse.json();
// 	const roles: IRole[] = await roleResponse.json();
// 	return {
// 		expiration,
// 		userId: user.userId,
// 		userFullName: user.userFullName,
// 		userEmail: user.email,
// 		avatar: user.thumbnail,
// 		roles: user.roles,
// 		claims: getClaimsByRoles(roles, user.roles),
// 	};
// };
const getClaimsByRoles = (roles, userRoleIds) => {
    // If logged in user is Admin then there is no need in other
    if (userRoleIds.includes(1))
        return [];
    const userRoles = roles.filter((role) => userRoleIds.includes(role.id));
    const claimsWithDuplicates = userRoles.map((userRole) => userRole.claims).flat();
    return [...new Set(claimsWithDuplicates)];
};
/**
 * This separate fetch method is required since right after token received it is not yet existed in store state and local storage.
 * That is why it is used manually here.
 * */
export const getCurrentUser = async (token) => {
    const { expiration, userId } = decodeToken(token);
    const userInfo = await fetch(`${getApiUrl()}/api/userinfo`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            // 'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then((result) => result.json());
    return {
        expiration,
        userId: userInfo.userId,
        userFullName: userInfo.userFullName,
        userEmail: userInfo.userEmail,
        avatar: userInfo.thumbnail,
        roles: userInfo.roles,
        claims: userInfo.claims,
    };
};
export const { useLoginMutation } = authApi;
