/**
 * Transform function to be used with yup {@link number().transform()}
 *
 * Transforms empty value from TextField to null.
 *
 * @param value - A value after all validations
 * @param originalValue - A value that enters a validator
 * @returns
 */
export const emptyToNull = (value, originalValue) => (originalValue === '' || originalValue === null ? null : value);
/**
 * Transform function to be used with yup {@link number().transform()}
 *
 * Transforms empty value from TextField to 0.
 *
 * @param value - A value after all validations
 * @param originalValue - A value that enters a validator
 * @returns
 */
export const emptyToZero = (value, originalValue) => (originalValue === '' || originalValue === null ? 0 : value);
