import { styled } from '@mui/material';
export const CheckIconContainer = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    paddingRight: '1rem',
    color: theme.palette.primary.dark,
    // '.Mui-focused &': {
    // 	color: theme.palette.common.white,
    // },
}));
export const optionStyle = { paddingRight: '2.25rem', position: 'relative' };
export const sxAvatar = { width: '1.25rem', height: '1.25rem', fontSize: '.75rem', marginRight: '.75rem' };
