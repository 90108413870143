import { TableCell, checkboxClasses, styled, tableCellClasses } from '@mui/material';
export const TableCellForCheckbox = styled(TableCell)({
    [`&.${tableCellClasses.sizeMedium}`]: {
        padding: '0 .25rem',
        [`.${checkboxClasses.root}`]: {
            paddingTop: '6px',
            paddingBottom: '6px',
        },
    },
});
