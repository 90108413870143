import { styled } from '@mui/material';
const DrawerFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'right',
    margin: 0,
    padding: '.75rem 1.5rem',
    backgroundColor: theme.palette.grey[50],
    '> button:not(:first-of-type)': {
        marginLeft: '0.75rem',
    },
}));
export { DrawerFooter };
