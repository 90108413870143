import styled from '@mui/styled-engine';
const LoadingWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'fullHeight' })(({ theme, fullHeight }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.75rem',
    padding: '.375rem',
    height: fullHeight ? '100%' : undefined,
}));
export { LoadingWrapper };
