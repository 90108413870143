import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { customerApi, customerReducer } from './modules/customers/business';
import { identityApi, identityStateReducer } from './modules/settings/identity/business';
import { orderReducer, ordersApi } from './modules/orders/business';
import { priceListApi, priceListReducer } from './modules/priceLists/business';
import { stockApi, stockStateReducer } from './modules/stock/business';
import { stockSettingsApi, stockSettingsStateReducer } from './modules/settings/stock/business';
import { storageApi, storageStateReducer } from './modules/settings/storage/business';
import { supplierApi, supplierReducer } from './modules/suppliers/business';
import { authApi } from 'shared/modules/auth/business';
import { brandApi } from './modules/brands/business';
import { generalSettingsApi } from './modules/settings/generalSettings/business';
import { isDevelopmentMode } from 'shared/helpers/environmentHelper';
import { logoApi } from './modules/logo/business';
import { reclamationApi } from './modules/reclamations/business';
import { shipmentApi } from './modules/shipment/business';
const rootReducer = combineReducers({
    customers: customerReducer,
    orders: orderReducer,
    suppliers: supplierReducer,
    priceLists: priceListReducer,
    stock: stockStateReducer,
    identity: identityStateReducer,
    stockSettings: stockSettingsStateReducer,
    storage: storageStateReducer,
    [authApi.reducerPath]: authApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [supplierApi.reducerPath]: supplierApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [logoApi.reducerPath]: logoApi.reducer,
    [generalSettingsApi.reducerPath]: generalSettingsApi.reducer,
    [priceListApi.reducerPath]: priceListApi.reducer,
    [reclamationApi.reducerPath]: reclamationApi.reducer,
    [stockApi.reducerPath]: stockApi.reducer,
    [stockSettingsApi.reducerPath]: stockSettingsApi.reducer,
    [storageApi.reducerPath]: storageApi.reducer,
    [shipmentApi.reducerPath]: shipmentApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
});
export const store = configureStore({
    reducer: rootReducer,
    devTools: isDevelopmentMode(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware, customerApi.middleware, supplierApi.middleware, ordersApi.middleware, brandApi.middleware, logoApi.middleware, generalSettingsApi.middleware, priceListApi.middleware, reclamationApi.middleware, stockApi.middleware, stockSettingsApi.middleware, storageApi.middleware, shipmentApi.middleware, identityApi.middleware),
});
if (isDevelopmentMode() && module.hot) {
    module.hot.accept(() => store.replaceReducer(rootReducer));
}
// TODO: to delete (the save as above. What to choose?)
// export interface AppSyncThunk<Payload = void, D = void, S = void> {
// 	(payload: Payload extends void ? Payload | undefined : Payload): (
// 		dispatch: D extends void ? AppDispatch : D,
// 		getState: () => S extends void ? RootState : S
// 	) => void;
// }
