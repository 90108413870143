import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink, Navigate } from 'react-router-dom';
import { ProtectedRoute } from 'shared/modules/auth';
import { SvgIcon } from 'core/ui';
import { claims } from 'shared/modules/auth/models';
import { createBrowserRouter } from 'react-router-dom';
import { customerApi } from 'admin/modules/customers/business';
import houseIcon from 'core/svg/fa/house.svg';
import { store } from 'admin/store';
import { t } from 'shared/translations';
// Multiple Routes in a single file.
// If you want to avoid loading multiple chunks for **nested** routes, you could store them all in the same file and return them to the individual routes.
// Modern bundlers will latch onto the same Promise for the different import() invocations.
// Example below: <CrossdockingReceipt /> and <CrossdockingReceiptItems /> both have the same import path and therefore have a single chunk.
// For any RouteObject that is gonna use breadcrumb data, RouteObjectWithCrumbs type should be used for proper type safety.
export const router = createBrowserRouter([
    {
        path: 'login',
        lazy: async () => {
            const { Login } = await import('shared/modules/auth/Login');
            return { Component: Login };
        },
    },
    {
        path: '/',
        lazy: async () => {
            const { AuthorizedLayout } = await import('admin/navigation/AuthorizedLayout');
            return { Component: AuthorizedLayout };
        },
        handle: {
            crumb: () => (_jsx(NavLink, { to: "/", title: "home", children: _jsx(SvgIcon, { icon: houseIcon, size: "sm" }) })),
        },
        children: [
            // Orders route
            { path: '/', element: _jsx(Navigate, { to: "orders", replace: true }) },
            {
                path: 'orders',
                lazy: async () => {
                    const { Orders } = await import('admin/modules/orders');
                    return {
                        element: (_jsx(ProtectedRoute, { allow: ['CustomerHeadManager', 'CustomerManager', 'SupplierHeadManager', 'SupplierManager'], children: _jsx(Orders, {}) })),
                    };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/orders", children: t('orders.section') }),
                },
            },
            // Customers route
            {
                path: 'customers',
                element: _jsx(ProtectedRoute, { allow: ['CustomerHeadManager', 'CustomerManager'] }),
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/customers", children: t('clients.section') }),
                },
                children: [
                    {
                        index: true,
                        lazy: async () => {
                            const { Customers } = await import('admin/modules/customers');
                            return { Component: Customers };
                        },
                    },
                    { path: ':customerId', element: _jsx(Navigate, { to: "markup", replace: true, relative: "path" }) },
                    {
                        path: ':customerId',
                        lazy: async () => {
                            const { CustomerSettings } = await import('admin/modules/customers/components/CustomerSettings');
                            return { Component: CustomerSettings };
                        },
                        children: [
                            {
                                path: 'markup',
                                lazy: async () => {
                                    const { PriceListMarkupTab } = await import('admin/modules/customers/components/CustomerSettings');
                                    return {
                                        element: (_jsx(ProtectedRoute, { allow: claims.filter((claim) => !['CustomerManager', 'CustomerHeadManager'].includes(claim)), children: _jsx(PriceListMarkupTab, {}) })),
                                    };
                                },
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/customers/${params.customerId}/markup`, relative: "path", children: t('customer.settings.label') })),
                                },
                            },
                            {
                                path: 'orders',
                                children: [
                                    {
                                        index: true,
                                        lazy: async () => {
                                            const { OrdersFromFileTab } = await import('admin/modules/customers/components/CustomerSettings');
                                            return { Component: OrdersFromFileTab };
                                        },
                                    },
                                ],
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/customers/${params.customerId}/orders`, relative: "path", children: t('customer.settings.label') })),
                                },
                            },
                        ],
                    },
                    {
                        // That could be only { path: ':customerId/orders/:ruleId', lazy: ... }, but splitting to path with children is required for breadcrumb.
                        path: ':customerId/orders',
                        loader: async ({ params: { customerId } }) => {
                            if (!Number(customerId))
                                return new Promise((resolve) => resolve({ firstName: '' }));
                            // Getting cached data
                            const promise = store.dispatch(customerApi.endpoints.getCustomer.initiate(Number(customerId)));
                            const { data: customer, isError } = await promise;
                            return {
                                firstName: isError || !customer ? '' : customer.firstName,
                            };
                        },
                        handle: {
                            crumb: ({ params, loaderData }) => (_jsx(NavLink, { to: `/customers/${params.customerId}/orders`, relative: "path", children: params.ruleId ? loaderData.firstName ?? t('customer.settings.label') : t('customer.settings.label') })),
                        },
                        children: [
                            {
                                path: ':ruleId',
                                lazy: async () => {
                                    const { OrdersFromFileRuleEdit } = await import('admin/modules/customers/components/CustomerSettings/OrdersFromFileRuleEdit');
                                    return { Component: OrdersFromFileRuleEdit };
                                },
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/customers/${params.customerId}/orders/${params.ruleId}`, children: params.ruleId === 'add' ? t('add.new.order.import.rule') : t('edit.order.import.rule') })),
                                },
                            },
                        ],
                    },
                ],
            },
            // Suppliers route.
            {
                path: 'suppliers',
                lazy: async () => {
                    const { Suppliers } = await import('admin/modules/suppliers');
                    return {
                        element: (_jsx(ProtectedRoute, { allow: ['SupplierHeadManager', 'SupplierManager'], children: _jsx(Suppliers, {}) })),
                    };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/suppliers", children: t('suppliers.section') }),
                },
            },
            // PriceLists route
            {
                path: 'priceLists',
                lazy: async () => {
                    const { PriceLists } = await import('admin/modules/priceLists');
                    return {
                        element: (_jsx(ProtectedRoute, { children: _jsx(PriceLists, {}) })),
                    };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/priceLists", children: t('pricelists.section') }),
                },
            },
            // Reclamations route
            {
                path: 'reclamations',
                lazy: async () => {
                    const { Reclamations } = await import('admin/modules/reclamations');
                    return {
                        element: (_jsx(ProtectedRoute, { children: _jsx(Reclamations, {}) })),
                    };
                },
            },
            // Stock route
            {
                path: 'stock',
                // `Warehouse` role (Warehouse manager) should have access to Stock section only in Warehouse, not Admin!
                // element: <ProtectedRoute allow={'Warehouse'} />,
                element: _jsx(ProtectedRoute, {}),
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/stock", children: t('warehouses.label') }),
                },
                children: [
                    {
                        index: true,
                        lazy: async () => {
                            const { StockList } = await import('admin/modules/stock/StockList');
                            return { Component: StockList };
                        },
                    },
                    {
                        path: ':stockId',
                        lazy: async () => {
                            const { Stock } = await import('admin/modules/stock/components/Stock');
                            return { Component: Stock };
                        },
                        handle: {
                            crumb: ({ params }) => _jsx(NavLink, { to: `/stock/${params.stockId}`, children: t('stock.label') }),
                        },
                    },
                ],
            },
            // Shipment route
            {
                path: 'shipment',
                lazy: async () => {
                    const { Shipment } = await import('admin/modules/shipment');
                    return {
                        element: (_jsx(ProtectedRoute, { allow: ['CustomerHeadManager', 'CustomerManager'], children: _jsx(Shipment, { readOnlyMode: true }) })),
                    };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/shipment", children: t('shipment.label') }),
                },
            },
            // Settings route
            {
                path: 'settings',
                element: _jsx(ProtectedRoute, { allow: 'Settings' }),
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/settings", children: t('settings.label') }),
                },
                children: [
                    {
                        index: true,
                        lazy: async () => {
                            const { Settings } = await import('admin/modules/settings');
                            return { Component: Settings };
                        },
                    },
                    {
                        path: 'general',
                        lazy: async () => {
                            const { GeneralSettings } = await import('admin/modules/settings/generalSettings');
                            return { Component: GeneralSettings };
                        },
                        handle: {
                            crumb: () => _jsx(NavLink, { to: `/settings/general`, children: t('general.settings.btn') }),
                        },
                    },
                    {
                        path: 'stock',
                        handle: {
                            crumb: () => _jsx(NavLink, { to: "/settings/stock", children: t('warehouses.label') }),
                        },
                        children: [
                            {
                                index: true,
                                lazy: async () => {
                                    const { StockSettingsList } = await import('admin/modules/settings/stock');
                                    return { Component: StockSettingsList };
                                },
                            },
                            {
                                path: ':stockId',
                                lazy: async () => {
                                    const { StockSettingsEdit } = await import('admin/modules/settings/stock/components/StockSettingsEdit');
                                    return { Component: StockSettingsEdit };
                                },
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/settings/stock/${params.stockId}`, children: params.stockId === 'add' ? t('stock.add.breadcrumb.label') : t('stock.edit.breadcrumb.label') })),
                                },
                            },
                        ],
                    },
                    {
                        path: 'storage',
                        handle: {
                            crumb: () => _jsx(NavLink, { to: "/settings/storage", children: t('warehouse.addresses.label') }),
                        },
                        children: [
                            {
                                index: true,
                                lazy: async () => {
                                    const { Storage } = await import('admin/modules/settings/storage/Storage');
                                    return { Component: Storage };
                                },
                            },
                            {
                                path: ':sector',
                                lazy: async () => {
                                    const { StorageSector } = await import('admin/modules/settings/storage/StorageSector');
                                    return { Component: StorageSector };
                                },
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/settings/storage/${params.sector}`, children: params.sector === 'add' ? t('storage.sector.add.breadcrumb.label') : t('storage.edit.sector.label') })),
                                },
                            },
                            {
                                path: ':sector/view',
                                lazy: async () => {
                                    const { StorageSectorView } = await import('admin/modules/settings/storage/StorageSectorView');
                                    return { Component: StorageSectorView };
                                },
                                handle: {
                                    crumb: ({ params }) => _jsx(NavLink, { to: `/settings/storage/${params.sector}/view`, children: t('storage.view.sector.label') }),
                                },
                            },
                        ],
                    },
                    {
                        path: 'identity',
                        lazy: async () => {
                            const { Identity } = await import('admin/modules/settings/identity');
                            return { Component: Identity };
                        },
                        children: [
                            {
                                index: true,
                                lazy: async () => {
                                    const { Users } = await import('admin/modules/settings/identity/components/Users');
                                    return { Component: Users };
                                },
                            },
                            {
                                path: 'users',
                                handle: {
                                    crumb: () => _jsx(NavLink, { to: "/settings/identity/users", children: t('users.settings.btn') }),
                                },
                                children: [
                                    {
                                        index: true,
                                        lazy: async () => {
                                            const { Users } = await import('admin/modules/settings/identity/components/Users');
                                            return { Component: Users };
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'roles',
                                handle: {
                                    crumb: () => _jsx(NavLink, { to: "/settings/identity/roles", children: t('users.settings.btn') }),
                                },
                                children: [
                                    {
                                        index: true,
                                        lazy: async () => {
                                            const { Roles } = await import('admin/modules/settings/identity/components/Roles');
                                            return { Component: Roles };
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'identity/users',
                        handle: {
                            crumb: () => _jsx(NavLink, { to: `/settings/identity/users`, children: t('users.settings.btn') }),
                        },
                        children: [
                            {
                                path: ':userId',
                                lazy: async () => {
                                    const { UserEdit } = await import('admin/modules/settings/identity/components/UserEdit');
                                    return { Component: UserEdit };
                                },
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/settings/identity/users/${params.userId}`, children: params.userId === 'add' ? t('user.creation.label') : t('user.editing.label') })),
                                },
                            },
                        ],
                    },
                    {
                        path: 'identity/roles',
                        handle: {
                            crumb: () => _jsx(NavLink, { to: `/settings/identity/roles`, children: t('users.settings.btn') }),
                        },
                        children: [
                            {
                                path: ':roleId',
                                lazy: async () => {
                                    const { RoleEdit } = await import('admin/modules/settings/identity/components/RoleEdit');
                                    return { Component: RoleEdit };
                                },
                                handle: {
                                    crumb: ({ params }) => (_jsx(NavLink, { to: `/settings/identity/roles/${params.roleId}`, children: params.roleId === 'add' ? t('role.creation.label') : t('role.editing.label') })),
                                },
                            },
                        ],
                    },
                ],
            },
            // Other routes
            {
                path: 'access',
                lazy: async () => {
                    const { NoAccess } = await import('shared/components');
                    return { Component: NoAccess };
                },
            },
            {
                path: '*',
                lazy: async () => {
                    const { NotFound } = await import('shared/components');
                    return { Component: NotFound };
                },
            },
        ],
    },
]);
