export const prepareForStorage = (filter) => {
    // Date range values for `custom` period should be stored in storage.
    if (filter.periodDateRange.period === 'custom')
        return filter;
    // Date range values for predefined periods should NOT be stored in storage.
    const result = {
        ...filter,
        periodDateRange: {
            ...filter.periodDateRange,
            periodDateFrom: null,
            periodDateTo: null,
        },
    };
    return result;
};
