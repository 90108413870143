import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Chip, TextField as MuiTextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { t } from 'shared/translations';
// type IProps<T> = AutocompletePartialProps<T> & GetQueryDataProps<T> & FormControllerProps;
//const ServerSearchAutocomplete = React.forwardRef(<T, >({ getQuery, onChange, value, captionProperty, isError: isErrorState, ...props }: IProps<T>, ref: React.Ref<T>) => {
/**
 * Sends remote request with search string and fills options with request response.
 * Uses only one property from response model named <param name="valueProperty" value="valueProperty" />.
 * @param multiple forces component to have Chips, otherwise text is used.
 * @param valueProperty
 * @returns an array with string values
 */
const ServerSearchAutocomplete = ({ multiple, valueProperty, isError: isErrorState, getQuery, onChange, ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data, isError: isQueryError } = getQuery({ search: searchTerm }, { skip: searchTerm.length < 2 });
    const options = useMemo(() => data?.map((value) => value[valueProperty]) || [], [data, valueProperty]);
    return (_jsx(Autocomplete, { multiple: multiple, 
        //freeSolo // Not to show empty "No options"?
        freeSolo // Returned to fix Mui console warning about item is not in list (None of the options match with `"LYKMC"`).
        : true, filterSelectedOptions: true, ...props, clearText: t('delete.all'), 
        //options={data || []}
        options: options, 
        // Fix console error "... `getOptionLabel` method of Autocomplete returned object (BMW) instead of a string ["BMW"] ..."
        // Only reproduced when multiple={false}
        getOptionLabel: (option) => (typeof option === 'string' || option instanceof String ? option : option.toString()), 
        //isOptionEqualToValue={(option, value) => option[captionProperty] === (value as T)[captionProperty]}
        filterOptions: (x) => x, 
        // Multiple forces component to have Chips, otherwise text is used.
        renderTags: multiple
            ? (value, getTagProps, ownerState) => {
                return value?.map((option, index) => {
                    // Key value should be passed within `key` prop only. When it used through spread operator it leads to react console error.
                    const { key, ...tagProps } = getTagProps({ index });
                    return _jsx(Chip, { variant: "filled", size: "small", color: "primary", label: option, ...tagProps }, key);
                });
            }
            : undefined, renderInput: (params) => (
        // Only Mui TextFiled works inside Autocomplete.
        _jsx(MuiTextField, { ...params, size: "small", placeholder: props.placeholder ?? t('search.label'), error: isErrorState, sx: multiple ? undefined : { '.MuiOutlinedInput-input': { height: '19px' } } })), 
        //value={props.value}
        onChange: (event, value) => {
            onChange(value);
        }, onInputChange: (event, value, reason) => {
            setSearchTerm(value.trim());
        }, noOptionsText: t('please.enter.the.first.two.letters.to.search') }));
};
export { ServerSearchAutocomplete };
