import { convertRangeToSequent, getSelectedCells, selectedRangePredicate, selectionIntersectsStored } from './storage.helpers';
import { defaultBrowserScrollerWidth, mapCellSize } from 'admin/modules/settings/storage/constants';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    sectorCells: [],
    sectorCellsIsFetching: false,
    sectorName: undefined,
    stockType: undefined,
    isDirty: false,
    sectorMapPreviewIsDrawing: false,
    sectorMapPreviewShouldDraw: false,
    sectorMap: {
        selectionIncludesStoredCells: false,
        scrollOffset: {
            left: 0,
            top: 0,
        },
        visibleCellsX: 0,
        selectedCellRange: undefined,
        selectedCellRangeSequent: undefined,
        viewMode: {
            selectedCellId: undefined,
            selectedStockId: undefined,
            selectedStockCellsCount: undefined,
        },
    },
};
const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        setSelectedCellRangeManually: (state, action) => {
            state.sectorMap.selectedCellRange = action.payload.selection;
            if (!action.payload.selection)
                return;
            const { fromY, toY, fromX, toX } = action.payload.selection;
            const total = !fromY || !toY || !fromX || !toX ? 0 : (toY - fromY + 1) * (toX - fromX + 1);
            state.sectorMap.selectedCellRange.total = total;
            state.sectorMap.selectedCellRangeSequent = convertRangeToSequent(state.sectorMap.selectedCellRange);
            if (state.sectorMap.selectedCellRangeSequent) {
                state.sectorMap.selectionIncludesStoredCells = selectionIntersectsStored(state.sectorCells, state.sectorMap.selectedCellRangeSequent);
            }
        },
        handleSectorMapMouseEvent: (state, action) => {
            const { type, shiftKey, clientX, clientY, buttons, currentTarget } = action.payload.event;
            // 0 - not a mouseup or mousedown event, 1 - left button pressed, 2 - right button pressed
            const isLeftButtonBeingPressed = buttons === 1;
            // Position relative to SectorMap top left corner.
            const sectorMapRect = currentTarget.getBoundingClientRect();
            const x = clientX - sectorMapRect.left;
            const y = clientY - sectorMapRect.top;
            const widthWithoutScroll = sectorMapRect.width - defaultBrowserScrollerWidth;
            const heightWithoutScroll = sectorMapRect.height - defaultBrowserScrollerWidth;
            if (type === 'mousedown' && isLeftButtonBeingPressed) {
                // Prevents currents selection from being broken by click on scroller. Scrolling should not break current selection.
                if (x > widthWithoutScroll || y > heightWithoutScroll)
                    return;
                const cellY = Math.floor((y + state.sectorMap.scrollOffset.top) / mapCellSize) + 1;
                const cellX = Math.floor((x + state.sectorMap.scrollOffset.left) / mapCellSize) + 1;
                state.sectorMap.selectedCellRange = { fromY: cellY, toY: cellY, fromX: cellX, toX: cellX };
            }
            else if (type === 'mousemove' && isLeftButtonBeingPressed) {
                if (state.sectorMap.selectedCellRange) {
                    const cellY = Math.floor((y + state.sectorMap.scrollOffset.top) / mapCellSize) + 1;
                    const cellX = Math.floor((x + state.sectorMap.scrollOffset.left) / mapCellSize) + 1;
                    // This is much-lag-preventer: no need to rerender on every mousemove when X and Y are still the same.
                    if (state.sectorMap.selectedCellRange.toY === cellY && state.sectorMap.selectedCellRange.toX === cellX)
                        return;
                    state.sectorMap.selectedCellRange = { ...state.sectorMap.selectedCellRange, toY: cellY, toX: cellX };
                }
            }
            state.sectorMap.selectedCellRangeSequent = convertRangeToSequent(state.sectorMap.selectedCellRange);
            if (state.sectorMap.selectedCellRangeSequent) {
                state.sectorMap.selectionIncludesStoredCells = selectionIntersectsStored(state.sectorCells, state.sectorMap.selectedCellRangeSequent);
            }
        },
        handleSectorMapMouseEventInViewMode: (state, action) => {
            const { type, shiftKey, clientX, clientY, buttons, currentTarget } = action.payload.event;
            // 0 - not a mouseup or mousedown event, 1 - left button pressed, 2 - right button pressed
            const isLeftButtonBeingPressed = buttons === 1;
            if (type !== 'mousedown' || !isLeftButtonBeingPressed)
                return;
            // Position relative to SectorMap top left corner.
            const sectorMapRect = currentTarget.getBoundingClientRect();
            const x = clientX - sectorMapRect.left;
            const y = clientY - sectorMapRect.top;
            const widthWithoutScroll = sectorMapRect.width - defaultBrowserScrollerWidth;
            const heightWithoutScroll = sectorMapRect.height - defaultBrowserScrollerWidth;
            // Prevents currents selection from being broken by click on scroller. Scrolling should not break current selection.
            if (x > widthWithoutScroll || y > heightWithoutScroll)
                return;
            const cellY = Math.floor((y + state.sectorMap.scrollOffset.top) / mapCellSize) + 1;
            const cellX = Math.floor((x + state.sectorMap.scrollOffset.left) / mapCellSize) + 1;
            const selectedCell = state.sectorCells.find((cell) => cell.sectorName === state.sectorName && cell.sectorY === cellY && cell.sectorX === cellX);
            // Not found selectedCell means that the not occupied ('white') cell is selected.
            state.sectorMap.viewMode = {
                selectedCellId: selectedCell?.stockCellId || undefined,
                selectedStockId: selectedCell?.stockId, // || undefined,
                selectedStockCellsCount: selectedCell ? state.sectorCells.filter((cell) => cell.stockId === selectedCell.stockId).length : undefined,
            };
        },
        // Simply store the value of scroll position.
        handleSectorMapScroll: (state, action) => {
            const { scrollLeft: left, scrollTop: top } = action.payload;
            state.sectorMap.scrollOffset = { left, top };
        },
        initSectorData: (state, action) => {
            const { data, sectorName } = action.payload;
            state = {
                ...initialState,
                ...(sectorName !== 'add' && {
                    sectorName: sectorName.toUpperCase(),
                    sectorCells: data ? data[sectorName] : [],
                    stockType: data?.[sectorName][0].stockType,
                }),
                // State may have already get the visibleCellsX count value. Prevent set it to initial 0.
                sectorMap: { ...initialState.sectorMap, visibleCellsX: state.sectorMap.visibleCellsX },
            };
            // 'state = initialState' doesn't work because we are changing the reference and redux toolkit has no way of knowing
            // that as it keeps the old reference. We must either mutate the original state OR return new object.
            return state;
        },
        setSectorCellsIsFetching: (state, action) => {
            state.sectorCellsIsFetching = action.payload;
        },
        setSectorStockType: (state, action) => {
            state.stockType = action.payload;
        },
        setSectorName: (state, action) => {
            state.sectorName = action.payload.toUpperCase();
        },
        setSectorMapWidth: (state, action) => {
            const widthWithoutScroll = action.payload - defaultBrowserScrollerWidth;
            state.sectorMap.visibleCellsX = Math.floor(widthWithoutScroll / mapCellSize);
        },
        addSelectionToStored: (state) => {
            const { sectorMap: { selectedCellRangeSequent }, sectorName, stockType, } = state;
            if (!selectedCellRangeSequent || !sectorName || !stockType)
                return;
            state.sectorCells = [...state.sectorCells, ...getSelectedCells(selectedCellRangeSequent, sectorName, stockType)];
            state.sectorMap.selectedCellRange = undefined;
            state.sectorMap.selectedCellRangeSequent = undefined;
            state.isDirty = true;
        },
        deleteSelectionFromStored: (state) => {
            const { selectedCellRangeSequent } = state.sectorMap;
            if (!selectedCellRangeSequent)
                return;
            state.sectorCells = state.sectorCells.filter((cell) => !selectedRangePredicate(cell, selectedCellRangeSequent));
            state.sectorMap.selectedCellRange = undefined;
            state.sectorMap.selectedCellRangeSequent = undefined;
            state.isDirty = true;
        },
        setSectorMapPreviewIsDrawing: (state, action) => {
            state.sectorMapPreviewIsDrawing = action.payload;
        },
        setSectorMapPreviewShouldDraw: (state, action) => {
            state.sectorMapPreviewShouldDraw = action.payload;
        },
        resetToDefaults: (state) => {
            state.sectorMap.selectedCellRange = undefined;
            state.sectorMap.selectedCellRangeSequent = undefined;
            state.isDirty = false;
        },
    },
    // extraReducers: (builder) => {
    // 	builder.addCase(getStorageSector.fulfilled, (state, action) => {
    // 		//state.storedCells.push(action.payload)
    // 		console.log('in extraReducers, getStorageSector.fulfilled, action.payload:', action.payload);
    // 		if (!action.payload) return state;
    // 		state.storedCells = action.payload['o'];
    // 	});
    // },
});
// export const getStorageSector = createAsyncThunk('stateApi/settings/storage/getSector', async (sectorName: string) => {
// 	console.log('in getStorageSector');
// 	// fetch not working here, cause need to use baseApiQuery
// 	const response = await baseApiQuery(`cellSettings/storageSector/${sectorName}`);
// 	console.log('response:', response);
// 	// const json = await response.json();
// 	// return json;
// 	// return (await response.json()) as IStorageSectors;
// 	// try {
// 	// 	const res = await response.json();
// 	// 	return res;
// 	// } catch (e) {
// 	// 	console.log('error:', e);
// 	// }
// });
export const { actions: storageActions, reducer: storageStateReducer } = storageSlice;
