import coinsIcon from 'core/svg/fa/coins.svg';
import dollarSignIcon from 'core/svg/fa/dollar-sign.svg';
import euroSignIcon from 'core/svg/fa/euro-sign.svg';
import rubleSignIcon from 'core/svg/fa/ruble-sign.svg';
import yenSignIcon from 'core/svg/fa/yen-sign.svg';
const getCurrencyIconByCode = (code) => {
    switch (code) {
        case 1: {
            return rubleSignIcon;
        }
        case 840: {
            return dollarSignIcon;
        }
        case 978: {
            return euroSignIcon;
        }
        // yuan and yen have the same sign
        case 156:
        case 392: {
            return yenSignIcon;
        }
        default: {
            return coinsIcon;
        }
    }
};
export { getCurrencyIconByCode };
