import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, styled } from '@mui/material';
import { closeSnackbar } from 'notistack';
export const SnackbarCloseButton = ({ snackbarKey }) => {
    return (_jsx(IconButtonStyled, { disableRipple: true, onClick: () => closeSnackbar(snackbarKey), children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "currentColor", "aria-hidden": "true", children: _jsx("path", { d: "M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" }) }) }));
};
const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    padding: 0,
    '> svg': {
        width: '1.25rem',
        height: '1.25rem',
    },
    ':not(:hover)': {
        color: theme.palette.text.disabled,
    },
}));
