import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from 'core/ui/TextField';
import { floatPositiveNumberLowRestriction, integerNumberRegex, wholePositiveAndNegativeNumberWithSingleMinusRegex, } from 'core/utils/validation';
import { forwardRef } from 'react';
// const NumericField = React.forwardRef(({ variant = 'whole', onChange, ...props }: Props, ref) => {
const NumericField = forwardRef(({ variant = 'whole', onChange, ...props }, ref) => {
    return (_jsx(TextField, { ref: ref, ...props, onChange: (event) => {
            const value = event.target.value;
            if (value === '') {
                onChange(null);
                return;
            }
            switch (variant) {
                case 'whole': {
                    if (integerNumberRegex.test(value)) {
                        // -- @ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        onChange(Number(value));
                    }
                    break;
                }
                case 'wholeNegative': {
                    if (wholePositiveAndNegativeNumberWithSingleMinusRegex.test(value)) {
                        // -- @ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        if (value === '-')
                            onChange(value);
                        // -- @ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        else
                            onChange(Number(value));
                    }
                    break;
                }
                case 'floatPositive': {
                    if (floatPositiveNumberLowRestriction.test(value)) {
                        // --@ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        onChange(value);
                    }
                    break;
                }
            }
        } }));
});
NumericField.displayName = 'NumericField';
export { NumericField };
