export const MuiTable = {
    styleOverrides: {
        root: {
            letterSpacing: '-.025em',
        },
    },
};
export const MuiTableRow = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            // Return if Mui default 'border-bottom' lined behavior is gonna be used.
            //'&:last-child td, &:last-child th': { border: 0 },
            '&.MuiTableRow-hover:hover': {
                backgroundColor: theme.palette.grey[50],
            },
        }),
        // not working but it should (working variant now with '&.MuiTableRow-hover:hover')
        hover: {
        // '&:hover': {
        // backgroundColor: 'red',
        // },
        },
    },
};
export const MuiTableCell = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            borderBottomWidth: 0,
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.neutral.lighter,
            // '.MuiTableBody-root > .MuiTableRow-root:first-of-type > &, .MuiTableHead-root > .MuiTableRow-root:first-of-type > &': {
            '.MuiTableRow-root:first-of-type > &': {
                borderTopColor: theme.palette.neutral.light,
            },
        }),
        sizeMedium: ({ ownerState, theme }) => ({
            fontSize: theme.typography.body1.fontSize,
            padding: '6px 16px',
        }),
        sizeSmall: ({ ownerState, theme }) => ({
            fontSize: theme.typography.caption.fontSize,
            padding: '5px 14px',
        }),
        sizeExtraSmall: ({ ownerState, theme }) => ({
            fontSize: theme.typography.helper.fontSize,
            // fontSize: theme.typography.caption.fontSize,
            padding: '3px 7px',
        }),
    },
};
