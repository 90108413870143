/* eslint-disable no-redeclare */
// TODO: to remove this one and use **providesList** , now **providesList** function allows 'id' property as default with corresponding typescript check.
/**
 * Function to use with RTK Query **providesTags** inside *EndpointBuilder* query.
 * Used to simplify list update routine.
 *
 * Use only if identifier property has 'id' name.
 *
 * @param resultsWithIds
 * @param tagType
 * @returns
 */
function providesListBase(resultsWithIds, tagType) {
    return resultsWithIds
        ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
        : [{ type: tagType, id: 'LIST' }];
}
/**
 * Function to use with RTK Query **providesTags** inside *EndpointBuilder* query.
 * Used to simplify list update routine.
 *
 * @param resultsWithIds
 * @param tagType
 * @param idFieldName
 * @returns
 */
function providesListWithCustomId(resultsWithIds, tagType, idFieldName) {
    return resultsWithIds
        ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map((item) => ({ type: tagType, id: item[idFieldName] }))]
        : [{ type: tagType, id: 'LIST' }];
}
function providesList(resultsWithIds, tagType, idFieldName) {
    return resultsWithIds
        ? [
            { type: tagType, id: 'LIST' },
            ...resultsWithIds.map((item) => ({ type: tagType, id: item[idFieldName ?? 'id'] })),
        ]
        : [{ type: tagType, id: 'LIST' }];
}
export { providesList };
