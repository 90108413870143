import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
export const IconContainer = ({ children }) => (
// TODO: to delete if no needed later.
// <Box component="span" height="24px" width="24px" display="flex" justifyContent="center" alignItems="center">
_jsx(SquareSpan, { children: children })
// </Box>
);
const SquareSpan = styled('span')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    width: '24px',
});
