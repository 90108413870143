import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, FormControlLabel, Paper } from '@mui/material';
import { Checkbox } from 'core/ui/Checkbox';
import { t } from 'shared/translations';
/**
 * CheckboxAutocomplete paper component.
 *
 * !IMPORTANT This component **must not be inline** since inline case causes the bug with scroll to top on change.
 * BUG: https://github.com/mui/material-ui/issues/31073
 * @param paperProps
 * @returns
 */
// prettier-ignore
const SelectAllPaper = ({ paperProps, chipColor, selectAll, nothingFound, onSelectAllCheckboxClick }) => {
    const { children, ...restPaperProps } = paperProps;
    return (_jsxs(Paper, { ...restPaperProps, children: [!nothingFound &&
                _jsxs(_Fragment, { children: [_jsx(Box, { onMouseDown: (event) => event.preventDefault(), pl: 1.5, py: 0.5, children: _jsx(FormControlLabel
                            // onClick handler used instead of Checkbox onChange since there happens bug when click not on checkbox icon itself but on label.
                            , { 
                                // onClick handler used instead of Checkbox onChange since there happens bug when click not on checkbox icon itself but on label.
                                onClick: (event) => {
                                    event.preventDefault(); // prevent blur
                                    onSelectAllCheckboxClick();
                                }, label: t('select.all.label'), control: _jsx(Checkbox, { color: chipColor, checked: selectAll }) }) }), _jsx(Divider, {})] }), children] }));
};
export { SelectAllPaper };
