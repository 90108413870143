import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    priceListEditDialog: {
        open: false,
        priceListId: undefined,
        initialIsFtpVerified: undefined,
    },
};
const priceListSlice = createSlice({
    name: 'priceList',
    initialState,
    reducers: {
        openPriceListEditDialog: (state, action) => {
            //state.priceListEditDialog = { ...action.payload, open: true };
            state.priceListEditDialog.open = true;
            state.priceListEditDialog.priceListId = action.payload?.priceListId;
        },
        closePriceListEditDialog: (state) => {
            state.priceListEditDialog = initialState.priceListEditDialog;
        },
        setInitialIsFtpVerified: (state, action) => {
            state.priceListEditDialog.initialIsFtpVerified = action.payload;
        },
    },
});
export const { actions: priceListActions, reducer: priceListReducer } = priceListSlice;
