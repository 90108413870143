export const MuiStepConnector = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            marginLeft: '15px',
            '&.Mui-disabled > .MuiStepConnector-line': {
                borderColor: theme.palette.grey[300],
            },
        }),
        // lineHorizontal: ({ ownerState, theme }) => ({
        lineVertical: ({ ownerState, theme }) => ({
            borderLeftStyle: 'solid',
            borderRightStyle: 'solid',
            borderLeftWidth: '1px',
            borderRightWidth: '1px',
            width: '2px',
            borderRadius: '1px',
            borderColor: theme.palette.primary.dark,
            marginTop: '-14px',
            marginBottom: '-16px',
            minHeight: ' 53px',
        }),
    },
};
export const MuiStepLabel = {
    styleOverrides: {
        iconContainer: {
            paddingRight: '1rem',
        },
    },
};
export const MuiStepIcon = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            fontSize: '2rem',
            '.MuiStepIcon-text': { fontSize: '12px' },
            '&.Mui-active:': {
                color: theme.palette.primary.dark,
            },
            '.Mui-disabled &': {
                color: theme.palette.grey[300],
            },
        }),
    },
};
