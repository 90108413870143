import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell, styled } from '@mui/material';
/**
 * Creates rowspanned cell and places a text to the top of the cell.
 *
 * @param {boolean} isLast
 * @public
 */
export const TableCellRowSpan = ({ rowSpan, isLast, children, ...props }) => {
    if (rowSpan === 1)
        return _jsx(TableCell, { ...props, children: children });
    return (_jsx(TableCellRowSpanStyled, { ...props, rowSpan: rowSpan, isLast: isLast, children: children }));
};
const TableCellRowSpanStyled = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'isLast' })(({ theme, isLast }) => {
    const result = {
        verticalAlign: 'top',
        '&.MuiTableCell-sizeSmall': {
            paddingTop: '1rem', // TODO: make two variants: 1rem form normal table size, .875rem for small table size.
        },
    };
    if (isLast)
        result.border = 'none';
    return result;
});
