import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, customPeriodDefaultDateFrom, customPeriodDefaultDateTo } from 'core/ui';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { PeriodDateRangeInnerContainer } from './PeriodDateRange.styled';
import { getPeriodDateRangeValues } from './helpers';
import { t } from 'shared/translations';
import { useMemo } from 'react';
/**
 * Sets date range and triggers {@link onChange} callback.
 * @param {PeriodDateRangeValue} value - Value
 * @param {(value: PeriodDateRangeValue) => void} onChange - callback that is called on value change.
 * @param {boolean | undefined} disabled - Switch the component to disabled state.
 * @param {string | undefined} buttonClassName - adds class to buttons.
 * @param {boolean} invalid - Shows that value entered is invalid.
 */
const PeriodDateRange = ({ value, disabled, invalid, buttonClassName, onChange }) => {
    const { period, periodDateFrom, periodDateTo } = value;
    // 	const [periodValues, setPeriodValues] = useState<PeriodValues>(
    // 	period === 'custom' && periodDateFrom && periodDateTo ? { periodDateFrom, periodDateTo } : { periodDateFrom: customPeriodDefaultDateFrom, periodDateTo: customPeriodDefaultDateTo}
    // );
    const periodValues = useMemo(() => period === 'custom'
        ? { periodDateFrom: periodDateFrom ?? customPeriodDefaultDateFrom, periodDateTo: periodDateTo ?? customPeriodDefaultDateTo }
        : getPeriodDateRangeValues(period), [period, periodDateFrom, periodDateTo]);
    // const [customDateFrom, setCustomDateFrom] = useState<Date | null>(
    // 	period === 'custom' && periodDateFrom ? periodDateFrom : customPeriodDefaultDateFrom
    // );
    // const [customDateTo, setCustomDateTo] = useState<Date | null>(period === 'custom' && periodDateTo ? periodDateTo : customPeriodDefaultDateTo);
    // const setCustomPeriodRange = () => {
    // 	onChange({
    // 		period: 'custom',
    // 		periodDateFrom: customDateFrom ?? customPeriodDefaultDateFrom,
    // 		periodDateTo: customDateTo ?? customPeriodDefaultDateTo,
    // 	});
    // };
    const setCustomPeriodRange = () => {
        onChange({
            period: 'custom',
            // periodDateFrom: periodDateFrom ?? customPeriodDefaultDateFrom,
            // periodDateTo: periodDateTo ?? customPeriodDefaultDateTo,
            ...periodValues,
        });
    };
    const handleDateFromChange = (value) => {
        // setCustomDateFrom(value);
        onChange({ period: 'custom', periodDateFrom: value, periodDateTo });
    };
    const handleDateToChange = (value) => {
        // setCustomDateTo(value);
        onChange({ period: 'custom', periodDateFrom, periodDateTo: value });
    };
    return (_jsxs(PeriodDateRangeInnerContainer, { children: [_jsxs(ToggleButtonGroup, { exclusive: true, color: "primary", size: "medium", value: period, disabled: disabled, onChange: (_, newPeriod) => {
                    if (newPeriod === null || newPeriod === period)
                        return;
                    if (newPeriod === 'custom') {
                        setCustomPeriodRange();
                        return;
                    }
                    onChange({ period: newPeriod, ...getPeriodDateRangeValues(newPeriod) });
                }, children: [_jsx(ToggleButton, { value: "day", className: buttonClassName, children: t('day.label') }), _jsx(ToggleButton, { value: "week", className: buttonClassName, children: t('week.label') }), _jsx(ToggleButton, { value: "month", className: buttonClassName, children: t('month.label') }), _jsx(ToggleButton, { value: "year", className: buttonClassName, children: t('year.label') }), _jsx(ToggleButton, { value: "custom", className: buttonClassName, children: t('period.label').toLocaleLowerCase() })] }), _jsx(DatePicker, { width: "dense", 
                // defaultValue={customDateFrom}
                // value={customDateFrom}
                // defaultValue={periodDateFrom ?? periodValues.periodDateFrom}
                value: periodDateFrom ?? periodValues.periodDateFrom, 
                // value={periodDateFrom}
                onChange: handleDateFromChange, disabled: period !== 'custom' || disabled, invalid: invalid }), _jsx(Typography, { children: `-` }), _jsx(DatePicker, { width: "dense", 
                // defaultValue={customDateTo}
                // value={customDateTo}
                // defaultValue={periodDateTo ?? periodValues.periodDateTo}
                value: periodDateTo ?? periodValues.periodDateTo, 
                // value={periodDateTo}
                onChange: handleDateToChange, disabled: period !== 'custom' || disabled, invalid: invalid })] }));
};
export { PeriodDateRange };
