export const MuiTooltip = {
    defaultProps: {
        enterDelay: 500,
        leaveDelay: 100,
    },
    styleOverrides: {
        tooltip: ({ ownerState, theme }) => ({
            fontSize: theme.typography.helper.fontSize,
        }),
    },
};
