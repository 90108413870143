// TODO: We use multiple ColorDotSelect components. So it's duplicated.
export const sxMuiSelectInputProps = {
    display: 'flex',
    alignItems: 'center',
    '&.MuiSelect-select': {
        height: '1.5rem',
    },
    '.MuiListItemIcon-root': {
        minWidth: '1.7rem',
    },
};
