import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { t } from 'shared/translations';
export const downloadBlob = ({ queryResult, fileName, }) => {
    if (queryResult.error) {
        enqueueSnackbar({
            variant: 'error',
            header: t('file.download.failed'),
            body: getErrorFromException(queryResult.error),
            persist: true,
        });
        return { data: null };
    }
    const hiddenElement = document.createElement('a');
    const url = window.URL || window.webkitURL;
    const blob = url.createObjectURL(queryResult.data);
    hiddenElement.href = blob;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName;
    hiddenElement.click();
    URL.revokeObjectURL(blob);
    return { data: null };
};
