export const MuiAutocomplete = {
    styleOverrides: {
        root: {},
        option: ({ ownerState, theme }) => ({
            '.MuiCheckbox-root': {
                padding: 0,
            },
            '&.Mui-focused': {
                //backgroundColor: `${theme.palette.primary.dark} !important`,
                //color: `${theme.palette.common.white} !important`,
                backgroundColor: `${theme.palette.primary.lightest50} !important`,
            },
            '&[aria-selected="true"]': {
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: theme.palette.common.white,
            },
            '&[aria-selected="true"]:not(.Mui-focused)': {
                backgroundColor: theme.palette.common.white,
            },
        }),
        popper: {
            boxShadow: '#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px',
        },
        inputRoot: ({ ownerState, theme }) => ({
            '.MuiAutocomplete-input': {
                width: '100%',
            },
            '.MuiIconButton-root': {
                color: theme.palette.grey[400],
            },
        }),
    },
};
