import 'shared/assets/fonts/GetVoIPGrotesque.woff2';
import 'shared/assets/fonts/GetVoIPGrotesque-Italic.woff2';
import InterItalic from 'shared/assets/fonts/Inter-italic.var.woff2';
import InterRoman from 'shared/assets/fonts/Inter-roman.var.woff2';
export const MuiCssBaseline = {
    styleOverrides: `
    @font-face {
      font-family: 'GetVoIP Grotesque';
      font-style: normal;
      font-weight: 400;
      font-display: block;
      src: local('GetVoIP'), local('GetVoIP Grotesque'), url("fonts/GetVoIPGrotesque.woff2") format('woff2');
    }

    @font-face {
      font-family: 'GetVoIPGrotesque-Italic';
      font-style: italic;
      font-weight: normal;
      font-display: block;
      src: url("fonts/GetVoIPGrotesque.woff2") format('woff2');
    }				

    @font-face {
      font-family: 'Inter var';
      font-weight: 100 900;
      font-display: swap;
      font-style: normal;
      font-named-instance: 'Regular';
      src: local('Inter'), local('Inter-Regular'), url(${InterRoman}) format('woff2');
    }
    @font-face {
      font-family: 'Inter var';
      font-weight: 100 900;
      font-display: swap;
      font-style: italic;
      font-named-instance: 'Italic';
      src: local('Inter'), local('Inter-Italic'), url(${InterItalic}) format('woff2');
    }				
  `,
};
