import * as translations from './';
// t - Translate (to chosen language).
// 1 arg: key,
// other args: strings for templated insertion {1} {2}.
export function t(...args) {
    if (!args || !args.length)
        return '';
    // let language = getStoredValue("all", "language");
    // if (language== "" || language==null || language==undefined || language=="undefined")
    //     language ="eng";
    let language = getLanguage(); // TODO: можно ли избежать постоянного дергания локалстораджа? вызов в componentDidMount() 1 раз, а затем использовать для t(). Или ещё лучше в редаксе хранить!
    let key = args[0];
    if (key.trim() === '')
        return '[empty translation]';
    let result = translations[language][key];
    if (result === undefined)
        return `[${key}]`;
    for (let k = 1; k < args.length; k++) {
        result = result.replace(new RegExp('\\{' + k + '\\}', 'g'), args[k]);
    }
    return result;
}
export function getLanguage() {
    // let result = getStoredValue("all", "language");
    let result = 'ru';
    //if (result === '' || result === null || result === undefined || result === 'undefined') result = 'ru';
    return result;
}
