export const MuiMenu = {
    styleOverrides: {
        paper: {
            // We have to use !important because MuiMenu-paper got overridden by MuiPopover-paper by default.
            boxShadow: '#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important',
            // This doesn't work without `!important` because overridden with inline styles further.
            transform: 'translateY(4px) !important',
        },
        list: {
            minWidth: '14rem',
            '.MuiDivider-root': {
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
            },
        },
    },
};
export const MuiMenuItem = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            '&[hidden]': { display: 'none' },
            '.MuiListItemIcon-root': {
                minWidth: '2.25rem',
            },
            ':hover': {
                backgroundColor: theme.palette.background.default,
            },
        }),
    },
};
