import { endOfDay, startOfDay, sub } from 'date-fns';
import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { formatDate } from 'core/utils/datetime';
export const reclamationApi = createApi({
    reducerPath: 'api/reclamations',
    tagTypes: ['Reclamations'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getReclamations: builder.query({
            query: ({ archive, dateFrom, dateTo }) => {
                //  Take last month's records when no date interval is specified.
                const now = new Date();
                if (!dateFrom && !dateTo) {
                    dateFrom = startOfDay(sub(now, { months: 1 }));
                    dateTo = endOfDay(now);
                }
                return {
                    method: 'POST',
                    url: 'reclamation',
                    body: {
                        archive,
                        dateFrom: dateFrom ? formatDate(dateFrom, `yyyy-MM-dd HH:mm:ss`) : undefined,
                        dateTo: dateTo ? formatDate(dateTo, `yyyy-MM-dd HH:mm:ss`) : undefined,
                    },
                };
            },
            transformResponse: (response) => response.map((reclamation) => ({ ...reclamation, createDate: new Date(reclamation.createDate) })),
        }),
    }),
    // From old BACK how reclamations were working. Now total rework expected.
    // export const getReturnRequest = () => (dispatch, getStore) => {
    // 	dispatch({ type: actionTypes.GET_RETURN });
    // 	api.getReturnRequest()
    // 		.then((res) => {
    // 			if (res.error)
    // 				dispatch(requestError(res.err));
    // 			else {
    // 				let returned =[], archivedReturned = [],
    // 				{reclamations, archivedReclamations, usersDictionary, suppliersDictionary} = getStore().reclamationsReducer;
    // 					map(res, (wh) => {
    // 						if(wh.returnRequestItem.verdict === null || wh.returnRequestItem.verdict === true) {
    // 							let bingo;
    // 								map(reclamations, (el)=>{
    // 									if(el.returnRequestId === wh.returnRequestItem.id) {
    // 										archivedReturned.push(returnedToReclamation(wh,usersDictionary, suppliersDictionary));
    // 										bingo = true
    // 									}
    // 								})
    // 								if(!bingo) returned.push( returnedToReclamation(wh,usersDictionary, suppliersDictionary));
    // 						}
    // 						if(wh.returnRequestItem.verdict === false) {
    // 							archivedReturned.push(returnedToReclamation(wh, usersDictionary, suppliersDictionary));
    // 						}
    // 					}),
    // 					returned = [...reclamations, ...returned],
    // 					archivedReturned = [...archivedReclamations, ...archivedReturned];
    // 				dispatch(getReturnSucccess({returned, archivedReturned}));
    // 			}
    // 		});
    // };
});
export const { useGetReclamationsQuery } = reclamationApi;
