import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
export const brandApi = createApi({
    reducerPath: 'api/brands',
    tagTypes: ['Brands'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getBrandsFiltered: builder.query({
            query: ({ search }) => ({
                url: 'brands/autocomplete',
                params: { brandNamePart: search },
                method: 'GET',
            }),
        }),
        getBrandIsSaved: builder.query({
            query: ({ logo }) => ({
                method: 'GET',
                url: 'brands/checksaved',
                params: { logo },
            }),
        }),
        linkUnrecognizedBrands: builder.mutation({
            query: ({ priceListLogo, links }) => ({
                method: 'POST',
                url: 'brands',
                params: { logo: priceListLogo },
                body: links,
            }),
        }),
    }),
});
export const { useGetBrandsFilteredQuery, useGetBrandIsSavedQuery, useLinkUnrecognizedBrandsMutation } = brandApi;
