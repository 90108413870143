import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { getApiUrl } from 'shared/helpers/environmentHelper';
import { tokenIsExpired } from 'shared/modules/auth/helpers';
import { useAuthStore } from 'shared/modules/auth/business';
const baseQuery = fetchBaseQuery({
    baseUrl: `${getApiUrl()}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('token');
        if (token)
            headers.set('authorization', `Bearer ${token}`);
        return headers;
    },
});
// From RTK Query official docs:
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    // Status 401 Unauthorized
    if (result.error && result.error.status === 401) {
        // try to get a new token
        const refreshResult = await baseQuery('/refreshToken', api, extraOptions);
        // refresh result: {error: {status: 404, data: null}}
        if (refreshResult.data) {
            // store the new token
            //api.dispatch(authActions.tokenRefreshSuccess({ token: refreshResult.data as string }));
            //api.dispatch(authActions.authSuccess({ token: refreshResult.data as string }));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        }
        else {
            // Sign out only if not there is no token in storage or the token has expired
            signOutIfRequired();
        }
    }
    return result;
};
const signOutIfRequired = () => {
    const { token, user, signOut } = useAuthStore.getState();
    if (!token || tokenIsExpired(user))
        signOut();
};
export { baseQueryWithReauth as baseApiQuery };
