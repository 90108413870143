export const MuiBreadcrumbs = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({}),
        ol: {
            paddingBottom: '.5rem',
        },
        li: ({ ownerState, theme }) => ({
            // Flex is necessary to proper home svg icon positioning.
            display: 'flex',
            color: theme.palette.text.muted,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '1.25rem',
            height: '1.25rem',
            ':hover': {
                color: theme.palette.text.medium,
            },
            '> a': {
                color: 'inherit',
                textDecoration: 'unset',
                svg: {
                    verticalAlign: 'text-top',
                    paddingTop: '1px',
                },
            },
        }),
        separator: ({ ownerState, theme }) => ({
            color: theme.palette.neutral.light,
            flexShrink: 0,
            width: '1.25rem',
            height: '1.25rem',
        }),
    },
};
