import { MailingMode, PriceListFileType } from 'admin/modules/priceLists/models';
import { array, boolean, date, number, object, string } from 'yup';
import { emptyToZero, strongEmail } from 'core/utils/validation';
import { getEnumValuesAsArray } from 'core/utils/convert';
import { t } from 'shared/translations';
import { yup } from 'core/utils/validation';
const schema = object({
    exportPriceListId: number().integer().required(),
    name: string().max(256).nullable().required(t('field.is.required.error')),
    subject: string()
        .max(256)
        .nullable()
        .when('useEmail', {
        is: false,
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required(t('field.is.required.error')),
    }),
    fileName: string()
        .max(256)
        .nullable()
        .when('useEmail', {
        is: false,
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.min(2).required(t('field.is.required.error')),
    }),
    fileType: number().oneOf(getEnumValuesAsArray(PriceListFileType)).required(),
    // Is used instead of PriceListUpdate's `includedPricelists` property for edit purposes.
    includedPriceListsCombined: array()
        .of(object({
        pricelistId: number().integer().positive().required(),
        markup: number().integer().min(0).nullable().defined(), // just positive() not working since we can get 0 initially from server
        logo: string().required(),
        checked: boolean().required(),
        // not required to check fields but still exist in interface
        name: string().nullable().defined(),
        supplierName: string().required(),
        updated: date().required(),
        deliveryTerms: number().integer().required(),
        rowCount: number().integer().nullable().required(),
    }))
        .required(),
    scheduleTime: array().of(string().min(5).max(5).required()).required(), // ['16:30:00', '20:30:00', '10:30:00'];
    //rowCount: number; // TODO: to delete (not used prolly)
    //isActive: boolean; // TODO: to delete (not used prolly)
    useArchivation4attaches: boolean().required(),
    // lastUpdate: Date; // TODO: to delete (not used prolly)
    // state: PriceListState; // 1; // TODO: to delete (not used prolly)
    mailingMode: number().oneOf(getEnumValuesAsArray(MailingMode)).required(),
    emails: array().of(strongEmail).required(), // ['stock_auto@autostels.ru'];
    useEmail: boolean().required(),
    useFtp: boolean().required(),
    ftpHost: string()
        .max(512)
        .when('useFtp', {
        is: false,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Host is required.'),
    }),
    ftpPort: yup
        .string()
        .tcpIpPortAsString()
        .when('useFtp', {
        is: false,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Port is required.'),
    }),
    ftpLogin: string()
        .max(256)
        .when('useFtp', {
        is: false,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Login is required.'),
    }),
    ftpPassword: string()
        .max(256)
        .when('useFtp', {
        is: false,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Password is required.'),
    }),
    ftpDirectory: string().max(256).optional(),
    markup: number().typeError('The field must be a number').integer().min(0).nullable().notRequired().transform(emptyToZero),
    markupAbsolute: number().typeError('The field must be a number').integer().min(0).nullable().notRequired().transform(emptyToZero),
    excludedBrands: array().of(string().required()).required(), // ['CASTROL', 'DAIHATSU'];
    excludedArticles: array().of(string().required()).required(), // ['MN128497', '63117419634', '34116889585', '63117419633', '1823388', 'A2712000315', '6301600XST11AD'];
    encoding: string().oneOf(['UTF-8', 'WINDOWS-1251']).required(),
    maxPrice: number().typeError('The field must be a number').integer().min(0).nullable().notRequired().transform(emptyToZero),
    minPrice: number().typeError('The field must be a number').integer().min(0).nullable().notRequired().transform(emptyToZero),
    isQuantityLimit: boolean().required(),
    quantityLimit: number().typeError('The field must be a number').integer().min(0).nullable().notRequired(),
});
const defaultValues = {
    exportPriceListId: 0,
    name: '',
    subject: null,
    fileName: null,
    fileType: PriceListFileType.Txt,
    includedPriceListsCombined: [],
    scheduleTime: [],
    useArchivation4attaches: false,
    mailingMode: MailingMode.Daily,
    emails: [],
    useEmail: true,
    useFtp: false,
    ftpHost: '',
    ftpPort: '',
    ftpLogin: '',
    ftpPassword: '',
    ftpDirectory: '',
    markup: null,
    markupAbsolute: null,
    excludedBrands: [],
    excludedArticles: [],
    encoding: 'UTF-8',
    maxPrice: null,
    minPrice: null,
    isQuantityLimit: false,
    quantityLimit: null,
};
export { schema, defaultValues };
