import { jsx as _jsx } from "react/jsx-runtime";
import { localInputSearchDebounceDelay, serverInputSearchDebounceDelay } from 'core/constants';
import { useCallback, useEffect, useState } from 'react';
import { TextFieldUncontrolledHighlighted } from './SearchTextField.styled';
import { t } from 'shared/translations';
import { useDebounce } from 'core/hooks';
/**
 * TextField intended for server or local search with specified character input debounce.
 * @param debounce input debounce.
 * @param placeholder input placeholder.
 * @param defaultValue use not to lose actual value on mount/unmount, if value is stored in redux state.
 * @param highlightWhenFilled warns the user that field is not empty.
 * @param onChange on search input change handler.
 */
const SearchTextField = ({ debounce = 'server', placeholder, defaultValue, highlightWhenFilled, onChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debounceDelay = debounce === 'server' ? serverInputSearchDebounceDelay : debounce === 'local' ? localInputSearchDebounceDelay : debounce;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
    const onSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value.trim());
    }, []);
    useEffect(() => {
        onChange(debouncedSearchTerm.toLocaleLowerCase());
    }, [onChange, debouncedSearchTerm]);
    // TODO: to delete later, when BT allows this highlight.
    // return <TextFieldUncontrolled placeholder={placeholder || t('search.label')} defaultValue={defaultValue} onChange={onSearchChange} />;
    return (_jsx(TextFieldUncontrolledHighlighted, { highlighted: highlightWhenFilled && Boolean(searchTerm.trim().length), placeholder: placeholder || t('search.label'), defaultValue: defaultValue, onChange: onSearchChange }));
};
export { SearchTextField };
