import { styled } from '@mui/material';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: theme.palette.grey[50],
    margin: 0,
    padding: '0.75rem 1.5rem',
    minHeight: '3.75rem',
    '> h3': { lineHeight: '2.1rem' },
}));
export { DrawerHeader };
