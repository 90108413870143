import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
/**
 * Wrap any `TextField` component with {@link RegexTestTooltip} to make it have tooltip that is to be shown when `regex.test` is failed.
 *
 * @param regex required - regular expression
 * @param value optional - value to be tested
 * @param message required - tooltip text to be shown when `regex.test` is failed.
 * @returns
 */
const RegexTestTooltip = ({ regex, value, message, children }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (value === undefined || value === null) {
            setOpen(false);
            return;
        }
        setOpen(!regex.test(value));
    }, [value, regex, setOpen]);
    return (_jsx(Tooltip, { arrow: true, open: open, title: message, slotProps: {
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            // moves tooltip up by 6px.
                            offset: [0, -6],
                        },
                    },
                ],
            },
        }, children: children }));
};
export { RegexTestTooltip };
