import { enUS, ru } from 'date-fns/locale';
import { format } from 'date-fns';
///
// The solution from date-fns (is not working).
// const getLocale = (locale: SupportedLocale) => import(`date-fns/locale/${locale}/index.js`);
// const locale = await getLocale('ru');
///
// By providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale.
// (Btw, it is possible to use {formatDate} from 'date-fns' with the same success. date-fns knows current locale somehow.
// Or we even can use setDefaultLocale() globally. ) It probably uses "Intl.DateTimeFormat().resolvedOptions().timeZone".
//
// eslint-disable-next-line unicorn/prevent-abbreviations
const formatDate = (date, formatStr = 'PP', locale = 'ru') => {
    return format(date, formatStr, {
        locale: locale === 'ru' ? ru : enUS,
    });
};
export { formatDate };
// const formatDate = (date: number | Date, formatStyle: string, locale: SupportedLocale) => {
// 	return format(date, formatStyle, {
// 		locale: getLocale(locale),
// 	});
// };
// TODO: later if more date-fns locales are needed:
// import * as Localization from 'expo-localization';
// import * as Locales from 'date-fns/locale';
// import { Locale } from 'date-fns';
// /**
//  * Looks up a date-fns locale from the Expo localization object.  This falls back to `en-US`
//  * @param localization Expo Localization object containing the locale and region.
//  * @returns date-fns locale.
//  */
// export function getDateFnsLocale({ locale, region }: Pick<typeof Localization, 'locale'|'region'>) : Locale {
//   return (
//     Locales[locale.substring(0, 2) + region] ?? Locales[locale.substring(0, 2)] ?? Locales.enUS
//   );
// }
// Tests:
// import { enUS, fr, frCA } from 'date-fns/locale';
// describe('date-fns locale lookup', () => {
//   it('Should find fr', () => {
//     expect(getDateFnsLocale({ locale: 'fr', region: null })).toBe(fr);
//   });
//   it('Should find fr-CA', () => {
//     expect(getDateFnsLocale({ locale: 'fr-CA', region: 'CA' })).toBe(frCA);
//   });
//   it('Should not find zz-ZZ', () => {
//     expect(getDateFnsLocale({ locale: 'zz-ZZ', region: 'ZZ' })).toBe(enUS);
//   });
// });
