import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
const initialState = {
    token: undefined,
    user: undefined,
    userClaims: [],
};
const useAuthStore = create()(immer((set) => ({
    ...initialState,
    authSuccess: (token, user) => set((state) => {
        const { claims, ...other } = user;
        state.token = token;
        state.user = other;
        state.userClaims = claims || [];
        if (token)
            localStorage.setItem('token', token);
        // Store both 'user' and 'userClaims' data to prevent unnecessary claims calculation and extra role dictionary fetching inside useAuth hook.
        if (user)
            localStorage.setItem('userData', JSON.stringify(user));
    }),
    updateByLocalStorage: (token, user) => set((state) => {
        const { claims, ...other } = user;
        state.token = token;
        state.user = other;
        state.userClaims = claims || [];
    }),
    signOut: () => {
        set(initialState);
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        // Navigate to login
        window.location.href = '/login';
    },
})));
export { useAuthStore };
