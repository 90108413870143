export const MuiInputBase = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            borderWidth: 0,
            textShadow: 'rgb(0, 0, 0) 0px 0px 0px',
        }),
        multiline: {
            padding: 0,
        },
        input: ({ ownerState, theme }) => ({
            color: theme.palette.text.primary,
            textShadow: 'rgb(0, 0, 0) 0px 0px 0px',
            padding: '0.375rem 0.75rem',
            paddingTop: '0.375rem',
            paddingBottom: '0.375rem',
            paddingRight: '0.75rem',
            paddingLeft: '0.75rem',
            lineHeight: '1.5rem',
            height: '1.5rem',
            '::placeholder': {
                color: theme.palette.grey[400],
                opacity: 1,
                textShadow: 'none',
            },
        }),
        adornedEnd: ({ ownerState, theme }) => ({
            color: theme.palette.text.muted,
        }),
    },
};
