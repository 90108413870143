// Unlike most port numbers, port 0 is a reserved port in TCP/IP networking, meaning that it should not be used in
// TCP or UDP messages. Network ports in TCP and UDP range from number zero up to 65535. Port numbers in the range
// between zero and 1023 are defined as non-ephemeral ports, system ports, or well-known ports. The Internet Assigned
// Numbers Authority (IANA) maintains an official listing of the intended usage of these port numbers on the internet,
// and system port 0 is not to be used.
export const smallestTcpPortNumber = 1;
export const highestTcpPortNumber = 65_535;
/**
 * After delete item from table request is successful and success snackbar is shown, RTK query only begins to refresh data in table
 * and small delay occurs. User still can see the item and snackbar both for some period of time, depending on network connection speed.
 * So useTimeout() should be used with the *constant*.
 */
export const tableItemRemovedSnackbarDelay = 800;
