import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseButtonContainer, IconContainer, RootContainer, TextContentContainer, snackbarContentStyle } from './SimpleSnackbar.styled';
import { SnackbarContent } from 'notistack';
import { SnackbarCloseButton, SnackbarIcon } from './components';
import { Typography } from '@mui/material';
import { forwardRef } from 'react';
const SimpleSnackbar = forwardRef(({ id, header, body, variant, icon, ...props }, ref) => {
    return (_jsx(SnackbarContent, { ref: ref, style: snackbarContentStyle, children: _jsxs(RootContainer, { alignCenter: header !== undefined && !body, children: [variant !== 'default' && (_jsx(IconContainer, { children: _jsx(SnackbarIcon, { variant: variant, icon: icon }) })), _jsxs(TextContentContainer, { children: [header && (_jsx(Typography, { variant: "body1", fontWeight: ({ typography }) => typography.fontWeightMedium, children: header })), body && (_jsx(Typography, { variant: "body1", marginTop: ".25rem", color: ({ palette }) => palette.text.muted, children: body }))] }), _jsx(CloseButtonContainer, { children: _jsx(SnackbarCloseButton, { snackbarKey: id }) })] }) }));
});
export { SimpleSnackbar };
