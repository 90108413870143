import { TableContainer, styled } from '@mui/material';
/**
 *  Main goal of this Wrapper to hide scroller's keen edges that go beyond the boundaries of a {@link TableContainer}
 */
export const DialogTableContainerWrapper = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.neutral.light,
    borderRadius: `${theme.shape.borderRadius}px`,
}));
/**
 * Used inside {@link DialogTableContainerWrapper} component.
 */
export const TableContainerStyled = styled(TableContainer, {
    shouldForwardProp: (prop) => prop !== 'height',
})(({ theme, height }) => ({
    boxShadow: 'none',
    height: height,
    maxHeight: height,
}));
