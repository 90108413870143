import { TextField, autocompleteClasses, formControlLabelClasses, outlinedInputClasses, styled } from '@mui/material';
import { Checkbox } from 'core/ui';
export const FilterTextField = styled(TextField)({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [`.${outlinedInputClasses.root}`]: {
        padding: '0 .5rem !important',
    },
});
export const SelectAllCheckboxContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: '.5rem 0',
    ':hover': {
        // backgroundColor: theme.palette.primary.lightest50,
        backgroundColor: theme.palette.grey[100],
    },
    [`.${formControlLabelClasses.root}`]: {
        width: '100%',
        padding: '6px 16px',
        marginLeft: 0,
    },
}));
export const SelectAllCheckbox = styled(Checkbox)({
    padding: 0,
    marginRight: '.5rem',
});
export const PaperHead = styled('div')({ display: 'flex', flexDirection: 'column', paddingTop: '1rem' });
export const optionStyle = { paddingRight: '2.25rem', position: 'relative' };
// TODO: to delete all unnecessary comments
export const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    borderRadius: 0,
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        //fontSize: 13,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        // width: '100%',
    },
    // [`& .${autocompleteClasses.listbox}`]: {
    // 	backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    // 	padding: 0,
    // 	[`& .${autocompleteClasses.option}`]: {
    // 		minHeight: 'auto',
    // 		alignItems: 'flex-start',
    // 		padding: 8,
    // 		borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
    // 		'&[aria-selected="true"]': {
    // 			backgroundColor: 'transparent',
    // 		},
    // 		[`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
    // 			backgroundColor: theme.palette.action.hover,
    // 		},
    // 	},
    // },
    // [`&.${autocompleteClasses.popperDisablePortal}`]: {
    // 	position: 'relative',
    // },
}));
