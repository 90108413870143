import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'shared/modules/auth';
const RequireAuth = ({ children }) => {
    const { token } = useAuth();
    const location = useLocation();
    if (!token)
        return _jsx(Navigate, { to: "login", state: location, replace: true });
    return _jsx(_Fragment, { children: children });
};
export { RequireAuth };
