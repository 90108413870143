import { formatDate } from 'date-fns';
/**
 * Formats date value to string in a way the backend api can accept.
 *
 * Btw:
 * Converts **utc** date as:
 *
 * dateFrom: `Mon Jun 24 2024 00:00:00 GMT+0300 (Moscow Standard Time)`, dateTo: `Tue Aug 23 2024 23:59:59 GMT+0300 (Moscow Standard Time)`
 *
 * to string:
 *
 * dateFrom: `"2024-06-24T00:00:00.000Z"`, dateTo: `"2024-08-23T23:59:59.999Z"`
 *
 * @param {Date} date
 * @returns
 */
// export const formatDateForApiRequest = (date: Date) => formatDate(date, `yyyy-MM-dd'T'HH:mm:ss`);
export const formatDateForApiRequest = (date) => formatDate(date, `yyyy-MM-dd'T'HH:mm:ss.SSS`) + 'Z';
