import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
const FormBox = ({ children, ...props }) => {
    return (_jsx(BoxFlex1, { component: "form", 
        // Prevent form submission to avoid fields to be passed as url parameters.
        onSubmit: (event) => event.preventDefault(), ...props, children: children }));
};
const BoxFlex1 = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
}));
export { FormBox };
