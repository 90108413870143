import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from '@mui/material';
import { getBalanceColor } from 'admin/helpers';
export const BalanceLink = ({ balance, children, onClick }) => {
    return (_jsx(Link, { onClick: onClick, sx: ({ palette }) => ({
            color: getBalanceColor(balance, palette).main,
            ':hover': {
                color: getBalanceColor(balance, palette).hover,
            },
        }), children: children }));
};
