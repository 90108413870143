import { backdropClasses } from '@mui/material';
export const MuiDialog = {
    styleOverrides: {
        root: {
            [`> .${backdropClasses.root}`]: {
                borderRadius: 0,
            },
        },
        paper: {
            borderRadius: '0.5rem',
        },
    },
};
export const MuiDialogTitle = {
    defaultProps: {
        component: 'div',
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            padding: '.75rem 1.5rem',
            minHeight: '3.75rem',
            backgroundColor: theme.palette.grey[50],
            // Overridden by DialogTitleCaption and DialogTitleEntityId components
            // '.MuiTypography-h4': {
            // 	lineHeight: '2.25rem',
            // },
            // '.MuiTypography-h3': {
            // 	lineHeight: '2.1rem',
            // },
        }),
    },
};
export const MuiDialogContent = {
    styleOverrides: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 1.5rem',
            '.MuiDialogTitle-root+&': {
                paddingTop: '1rem',
            },
        },
    },
};
export const MuiDialogContentText = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            fontSize: theme.typography.body2.fontSize,
        }),
    },
};
export const MuiDialogActions = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            padding: '.75rem 1.5rem',
            backgroundColor: theme.palette.grey[50],
            '> :not(:first-of-type)': {
                marginLeft: '.75rem',
            },
        }),
    },
};
