import { jsx as _jsx } from "react/jsx-runtime";
import { PeriodDateRange, getPeriodDateRangeValues, periodDateRangeDeserializer } from 'core/ui';
import { useEffect, useMemo } from 'react';
import { useCoreSessionStorage } from 'core/hooks';
import { useDebounceCallback } from 'usehooks-ts';
// TODO: to delete all old comments if we have decided to use this version.
const debouncedDateRangeValueDelay = 900;
/**
 * Enhances {@link PeriodDateRange} component.
 *
 * Addons: stores value in *sessionStorage*, adds debounce on value change.
 *
 * **Important**: should be used with explicit generic type.
 *
 * @param {string} sessionStorageKey - required, key under which the value will be stored.
 * @param {boolean} [disabled] - optional
 * @param {string} [buttonClassName] - optional, className to be applied to all toggle buttons.
 * @param {(value: DateRangeFromTo) => void} onChange
 * @returns
 */
const StoredPeriodDateRange = ({ sessionStorageKey, defaultPeriod = 'month', disabled, buttonClassName, onChange, }) => {
    const initialValue = useMemo(() => ({
        period: defaultPeriod,
        // // last two weeks for `custom` range
        // periodDateFrom: customPeriodDefaultDateFrom,
        // periodDateTo: customPeriodDefaultDateTo,
        ...getPeriodDateRangeValues(defaultPeriod),
    }), [defaultPeriod]);
    const [storageValue, setStorageValue] = useCoreSessionStorage(sessionStorageKey, initialValue, {
        deserializer: periodDateRangeDeserializer(initialValue),
    });
    // // const debouncedValue = useDebounce(storageValue, debouncedDateRangeValueDelay);
    // const [debouncedValue] = useDebounceValue(storageValue, debouncedDateRangeValueDelay);
    // useEffect(() => {
    // 	if (debouncedValue.period === 'custom') {
    // 		onChange({ dateFrom: debouncedValue.periodDateFrom, dateTo: debouncedValue.periodDateTo });
    // 		return;
    // 	}
    // 	// Always generate actual values for predefined periods. It is necessary to not have the bug when on the next day values, gotten from
    // 	// storage, remain the same, but they shouldn't.
    // 	const { periodDateFrom, periodDateTo } = getPeriodDateRangeValues(debouncedValue.period);
    // 	onChange({ dateFrom: periodDateFrom, dateTo: periodDateTo });
    // }, [debouncedValue, onChange]);
    // const debouncedValue = useDebounce(storageValue, debouncedDateRangeValueDelay);
    const onChangeDebounced = useDebounceCallback(onChange, debouncedDateRangeValueDelay);
    useEffect(() => {
        // Force value update to have stored or initial value to pass to a query that handles onChange event (since value is null without that).
        onChange(getDates(storageValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(PeriodDateRange, { value: storageValue, disabled: disabled, invalid: false, buttonClassName: buttonClassName, onChange: (value) => {
            // setStorageValue(prepareForStorage(value, storageValue));
            setStorageValue(prepareForStorage(value));
            onChangeDebounced(getDates(value));
        } }));
};
// const prepareForStorage = (value: PeriodDateRangeValue, storageCurrentValue: PeriodDateRangeValue): PeriodDateRangeValue => {
// 	if (value.period === 'custom') return value;
// 	return {
// 		...value,
// 		periodDateFrom: storageCurrentValue.periodDateFrom,
// 		periodDateTo: storageCurrentValue.periodDateTo,
// 	};
// };
// const prepareForStorage = (value: PeriodDateRangeValue, storageCurrentValue: PeriodDateRangeValue): PeriodDateRangeValue => {
/**
 * We can't store `dateFrom` and `dateTo` values for periods (for all periods except `custom`) because these values will remain on the next day.
 * But on the next day new dates for `day`, `week`, `month` or `year` period to be chosen and shown.
 * That's why `dateFrom` and `dateTo` values should always contain date range for `custom` period only.
 */
const prepareForStorage = (value) => {
    if (value.period === 'custom')
        return value;
    return {
        ...value,
        periodDateFrom: null,
        periodDateTo: null,
    };
};
const getDates = (value) => ({ dateFrom: value.periodDateFrom, dateTo: value.periodDateTo });
export { StoredPeriodDateRange };
