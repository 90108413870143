import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip } from '@mui/material';
import { forwardRef, useState } from 'react';
/**
 * This tooltip only rendered after the specified action is executed and removed from the DOM right after the specified delay.
 */
const DynamicTooltip = forwardRef(({ children, BoxProps, ...rest }, ref) => {
    const [renderTooltip, setRenderTooltip] = useState(false);
    console.log('renderTooltip', renderTooltip);
    return (
    // <div onMouseEnter={() => !renderTooltip && setRenderTooltip(true)} className="display-contents">
    _jsxs(Box, { ...BoxProps, 
        // component={'span'}
        // ref={ref}
        onMouseEnter: () => {
            // TODO: to delete
            console.log('onMouseEnter:');
            // !renderTooltip && setRenderTooltip(true);
            setRenderTooltip(true);
        }, onMouseLeave: () => {
            setRenderTooltip(false);
        }, children: [!renderTooltip && children, renderTooltip && (_jsx(Tooltip
            // ref={ref}
            , { ...rest, open: true, 
                // title="ffffffffffffffffffffffffff"
                onClose: (event) => {
                    // TODO: to delete
                    console.log('onClose, event:', event);
                    // setRenderTooltip(false);
                }, children: children }))] }));
});
export { DynamicTooltip };
