export const orderFilterDeserializer = (initialValue) => (value) => {
    try {
        const parsed = JSON.parse(value);
        return {
            ...parsed,
            periodDateRange: {
                ...parsed.periodDateRange,
                periodDateFrom: parsed.periodDateRange.periodDateFrom ? new Date(parsed.periodDateRange.periodDateFrom) : null,
                periodDateTo: parsed.periodDateRange.periodDateTo ? new Date(parsed.periodDateRange.periodDateTo) : null,
            },
        };
    }
    catch (error) {
        console.error('Error parsing JSON:', error);
        return initialValue; // Return initialValue if parsing fails
    }
};
