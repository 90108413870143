import { jwtDecode } from 'jwt-decode';
import { renameObjectField } from 'core/utils/objects';
export const decodeToken = (token) => {
    const decodedToken = jwtDecode(token);
    renameObjectField(decodedToken, 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid', 'sid');
    renameObjectField(decodedToken, 'http://schemas.microsoft.com/ws/2008/06/identity/claims/pointOfIssuing', 'pointsOfIssuing');
    renameObjectField(decodedToken, 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication', 'authentication');
    const { exp, sid } = decodedToken;
    return { expiration: exp, userId: Number(sid) };
};
