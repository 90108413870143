import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'shared/modules/auth';
const ProtectedRoute = ({ allow, redirectPath = '/access', children }) => {
    const { currentUser } = useAuth();
    const requiredClaims = allow === undefined ? undefined : allow instanceof Array ? allow : [allow];
    if (currentUser && (currentUser.isAdmin || requiredClaims?.find((claim) => currentUser.claims.includes(claim)))) {
        // TODO: check if it is working and use instead.
        //if (currentUser.isAdmin || requiredClaims?.find((claim) => currentUser.claims.includes(claim))) {
        return children ? children : _jsx(Outlet, {});
    }
    return _jsx(Navigate, { to: redirectPath, replace: true });
};
export { ProtectedRoute };
