import { Typography, styled } from '@mui/material';
export const IconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '9999px',
    height: '2.5rem',
    width: '2.5rem',
    backgroundColor: theme.palette.info.lightest,
    '> svg': {
        fontSize: '1.5rem',
        color: theme.palette.info.dark,
    },
}));
export const TitleText = styled(Typography)(({ theme }) => ({
    lineHeight: '1.5',
    fontWeight: theme.typography.fontWeightBold,
}));
export const ContentText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.muted,
}));
export const TextInfoContainer = styled('div')({
    marginLeft: '1.5rem',
});
export const sxDialogContent = { padding: '24px 24px 16px 24px' };
