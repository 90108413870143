import boxesStackedIcon from 'core/svg/fa/boxes-stacked.svg';
import chartColumnIcon from 'core/svg/fa/chart-column.svg';
import dollyIcon from 'core/svg/fa/dolly.svg';
import fileExcelIcon from 'core/svg/fa/file-excel.svg';
import gearIcon from 'core/svg/fa/gear.svg';
import handHoldingDollarIcon from 'core/svg/fa/hand-holding-dollar.svg';
import listUlIcon from 'core/svg/fa/list-ul.svg';
import { t } from 'shared/translations';
import truckFastIcon from 'core/svg/fa/truck-fast.svg';
import usersIcon from 'core/svg/fa/users.svg';
export const sidebarNavigationItems = [
    {
        sectionName: t('general.sidebar.section'),
        menuItems: [
            {
                linkTo: '/orders',
                title: t('orders.section'),
                icon: listUlIcon,
                allow: ['CustomerHeadManager', 'CustomerManager', 'SupplierHeadManager', 'SupplierManager'],
            },
            {
                linkTo: '/customers',
                title: t('clients.section'),
                icon: usersIcon,
                allow: ['CustomerHeadManager', 'CustomerManager'],
            },
            {
                linkTo: '/suppliers',
                title: t('suppliers.section'),
                icon: truckFastIcon, // Old one is faShippingFast.
                allow: ['SupplierHeadManager', 'SupplierManager'],
            },
            {
                linkTo: '/priceLists',
                title: t('pricelists.section'),
                icon: fileExcelIcon,
            },
            {
                linkTo: '/reclamations',
                title: t('reclamations.section'),
                icon: handHoldingDollarIcon,
            },
            {
                linkTo: '/stock',
                title: t('warehouses.label'),
                icon: boxesStackedIcon, // or fa-shelves-empty
            },
            {
                linkTo: '/shipment',
                title: t('shipment.label'),
                icon: dollyIcon, // faForklift also fits
                allow: ['CustomerHeadManager', 'CustomerManager'],
            },
        ],
    },
    {
        sectionName: t('reports.section'),
        menuItems: [
            {
                linkTo: '/reports/suppliers',
                title: t('display.by.suppliers'),
                icon: chartColumnIcon, // old icon is faChartSimple
            },
            {
                linkTo: '/reports/customers',
                title: t('reports-clients.section'),
                icon: chartColumnIcon, // old icon is faChartSimple
            },
            {
                linkTo: '/reports/payments',
                title: t('reports-payments.section'),
                icon: chartColumnIcon, // old icon is faChartSimple
            },
        ],
    },
    {
        sectionName: t('settings.label'),
        menuItems: [
            {
                linkTo: '/settings',
                title: t('settings.section'),
                icon: gearIcon,
            },
        ],
    },
];
