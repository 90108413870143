export const MuiSwitch = {
    defaultProps: {
        size: 'small',
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            display: 'flex', // For change track height to 19px instead of 100% (18px).
            alignItems: 'center', // For change track height to 19px instead of 100% (18px).
            padding: '4px',
            width: '40px',
            '.MuiSwitch-switchBase': {
                paddingLeft: '5px',
            },
            '.MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(14px)',
            },
        }),
        switchBase: {},
        track: ({ ownerState, theme }) => ({
            height: '19px', // 100% initial Mui value (18px)
            borderRadius: '9999px',
            backgroundColor: theme.palette.neutral.lighter,
            opacity: 1,
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px inset, rgba(17, 24, 39, 0.05) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
            '.MuiSwitch-switchBase.Mui-checked + &': {
                backgroundColor: theme.palette.primary.dark,
                opacity: 1,
            },
            '.MuiSwitch-switchBase.Mui-disabled + &': {
                opacity: 0.4,
            },
        }),
        thumb: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.common.white,
        }),
        input: {
            left: '-50%',
            width: '200%',
        },
        sizeSmall: ({ ownerState, theme }) => ({
            '.MuiSwitch-root&': {
                width: '37px',
            },
            '.MuiSwitch-switchBase': {
                paddingLeft: '4px',
            },
            '.MuiSwitch-track': {
                height: '12px',
            },
            '.MuiSwitch-thumb': {
                border: `1px solid ${theme.palette.neutral.lighter}`,
                boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px',
            },
        }),
    },
};
