import { useReadLocalStorage } from 'usehooks-ts';
/**
 * DO NOT USE THE HOOK DIRECTLY. Use derived hooks for *Core*, *Admin app* or *Warehouse app* instead.
 *
 * @template T - The type of the state that is stored in local storage.
 * @template K - The fixed constant of the key to be used for instance hooks.
 * @param {string} key - The key under which the value is stored in local storage.
 * @param {?Partial<Options<T, true>>} [options] - Options for customizing the behavior of deserialization (optional).
 * @returns {T | null} - A stored value
 * @example
 * ```tsx
 * const useAppReadLocalStorage = <T>({ key, options }: Props<T>) => useReadLocalStorageBase(key, options);
 * ```
 */
export const useReadLocalStorageBase = (key, options) => useReadLocalStorage(key, options);
