import { colorTokens } from './colorTokens';
export const getPalette = (mode) => {
    const { indigo, grey, red, orange, yellow, green, blue, purple, slate } = colorTokens(mode);
    return {
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    // palette values for light mode
                    //contrastThreshold: 4.5,
                    grey: grey,
                    indigo: indigo, // only used for primary not having indigo[200]
                    purple: purple,
                    slate: slate,
                    yellow: {
                        darker: yellow[700],
                        dark: yellow[600],
                        main: yellow[500],
                        light: yellow[400],
                        lighter: yellow[300],
                        lightest: yellow[200],
                        background: yellow[100],
                        backgroundLight: yellow[50],
                        contrastText: yellow[100],
                    },
                    background: {
                        default: grey[100],
                        light: grey[50],
                    },
                    divider: grey[100], //amber[200],
                    text: {
                        primary: grey[900],
                        secondary: grey[800],
                        medium: grey[700],
                        light: grey[600],
                        muted: grey[500],
                        disabled: grey[400],
                    },
                    neutral: {
                        dark: grey[700],
                        main: grey[500],
                        light: grey[300],
                        lighter: grey[200],
                        //lightest: grey[100],
                        contrastText: '#ffffff', // <Chip color="neutral" /> fails without this.
                    },
                    primary: {
                        // black 900
                        // darkest 800
                        darker: indigo[700],
                        dark: indigo[600],
                        main: indigo[500],
                        light: indigo[400],
                        lighter: indigo[300],
                        lighter200: indigo[200],
                        lightest: indigo[100],
                        background: indigo[100],
                        lightest50: indigo[50],
                        backgroundLight: indigo[50],
                    },
                    secondary: {
                        darkest: purple[800],
                        darker: purple[700],
                        dark: purple[600],
                        main: purple[500],
                        light: purple[400],
                        lighter: purple[300],
                        lighter200: purple[200],
                        lightest: purple[100],
                        background: purple[100],
                        lightest50: purple[50],
                        backgroundLight: purple[50],
                    },
                    info: {
                        darker: blue[700],
                        dark: blue[600],
                        main: blue[500],
                        default: blue[500],
                        light: blue[400],
                        lighter: blue[300],
                        //lighter200: blue[200],
                        lightest: blue[100],
                        background: blue[100],
                        lightest50: blue[50],
                        backgroundLight: blue[50],
                    },
                    success: {
                        darker: green[700],
                        dark: green[600],
                        main: green[500],
                        default: green[500],
                        light: green[400],
                        lighter: green[300],
                        lighter200: green[200],
                        lightest: green[100],
                        background: green[100],
                        lightest50: green[50],
                        backgroundLight: green[50],
                    },
                    warning: {
                        // or use yellow?
                        darker: orange[700],
                        dark: orange[600],
                        main: orange[500],
                        default: orange[500],
                        light: orange[400],
                        lighter: orange[300],
                        lighter200: orange[200],
                        lightest: orange[100],
                        background: orange[100],
                        lightest50: orange[50],
                        backgroundLight: orange[50],
                    },
                    error: {
                        darker: red[700],
                        dark: red[600],
                        main: red[500],
                        default: red[500],
                        light: red[400],
                        lighter: red[300],
                        lighter200: red[200],
                        // lightest: red[200],
                        lightest: red[100],
                        background: red[100],
                        backgroundLight: red[50],
                        // TODO: check why typescript not working.
                        bad: red[50],
                        contrastText: yellow[100], // <Chip color="error" /> fails without this.
                    },
                    orderStatus: {
                        // 1
                        ordered: { link: grey[900], linkHover: grey[800], chip: grey[600], chipBackground: grey[100] },
                        // 2: "Отправлен поставщику",
                        sentToSupplier: { link: yellow[600], linkHover: yellow[500], chip: yellow[700], chipBackground: yellow[100] },
                        // 4: "Подтвержден поставщиком",
                        //		validatedBySupplier: StatusColor;
                        // 5: "Отменен поставщиком",
                        canceledBySupplier: { link: red[500], linkHover: red[400], chip: red[700], chipBackground: red[100] },
                        // 6: "Ожидается на складе testtestov",
                        expectedInWarehouse: { link: purple[500], linkHover: purple[400], chip: purple[700], chipBackground: purple[100] },
                        // 7: "Получен",
                        //received: { main: green[500], hover: green[400] },
                        // 8: "На складе testtestov",
                        inWarehouse: { link: blue[500], linkHover: blue[400], chip: blue[700], chipBackground: blue[100] },
                        // 9: "Отправлен заказчику",
                        sentToCustomer: { link: green[600], linkHover: green[500], chip: green[700], chipBackground: green[100] },
                        // 12: "Отменен администратором",
                        canceledByAdmin: { link: red[700], linkHover: red[600], chip: red[700], chipBackground: red[100] },
                        // 13: "Запрошен возврат",
                        //returnRequested: StatusColor;
                        // 14: "Возврат одобрен, ожидается на складе",
                        //returnApproved: StatusColor;
                        // 15: "Возврат отклонен",
                        //returnRejected: StatusColor;
                        // 16: "Возврат принят на складе",
                        //returnAccepted: StatusColor;
                        // 17: "Отменен заказчиком",
                        //canceledByCustomer: StatusColor;
                        // 18: "Принят в ПВЗ",
                        //acceptedInShipmentPoint: StatusColor; // Pickup point?
                        // 19: "Передан в службу доставки",
                        //transferredToDeliveryService: StatusColor;
                        // 20: "Передан в службу доставки (собств.)"
                        //transferredToOwnDeliveryService: StatusColor;
                    },
                    stockType: {
                        stock: {
                            main: purple[500],
                            // purple[300] lightened by 20%
                            stored: '#e0c3fe',
                            // purple[400] lightened by 20%
                            occupied: '#cd9dfd',
                            // mixed blue and purple
                            storedSelected: '#C2ADFD',
                            // mixed blue and purple
                            occupiedSelected: '#9A83FA',
                            occupiedBorder: purple[950],
                        },
                        crossdocking: {
                            main: yellow[400],
                            stored: yellow[200],
                            occupied: yellow[300],
                            // mixed blue[500] and yellow[300] take 30%
                            storedSelected: '#DACF67',
                            // mixed blue[500] and yellow[300] take 50%
                            occupiedSelected: '#B6BE87',
                            occupiedBorder: yellow[700],
                        },
                        // // Same as stock right now
                        // transfer: {
                        // 	main: purple[500],
                        // 	stored: purple[300],
                        // 	occupied: purple[400],
                        // 	// mixed blue and purple
                        // 	storedSelected: '#C2ADFD',
                        // 	// mixed blue and purple
                        // 	occupiedSelected: '#9A83FA',
                        // 	occupiedBorder: purple[950],
                        // } as StockTypeColor,
                    },
                }
                : {
                // // palette values for dark mode
                // primary: deepOrange,
                // divider: deepOrange[700],
                // background: {
                // 	default: deepOrange[900],
                // 	paper: deepOrange[900],
                // },
                // text: {
                // 	primary: '#fff',
                // 	secondary: grey[500],
                // },
                }),
        },
    };
};
