import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';
const getIconSize = (size, pxToRem) => size === '3xs'
    ? pxToRem(12)
    : size == '2xs'
        ? pxToRem(13)
        : size === 'xs'
            ? pxToRem(14)
            : size === 'sm'
                ? pxToRem(15)
                : size === 'md'
                    ? pxToRem(16)
                    : size === 'lg'
                        ? pxToRem(17)
                        : size === 'xl'
                            ? pxToRem(18)
                            : size === '2xl'
                                ? pxToRem(19)
                                : size === '3xl'
                                    ? pxToRem(20)
                                    : size === '4xl'
                                        ? pxToRem(22)
                                        : size;
export const SvgIconStyled = styled(({ color, ...props }) => _jsx(MuiSvgIcon, { ...props }), {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'size' && prop !== 'color' && prop !== 'transition',
})(({ theme, size = 'md', color, transition, disabled }) => ({
    height: getIconSize(size, theme.typography.pxToRem),
    width: getIconSize(size, theme.typography.pxToRem),
    color: disabled ? theme.palette.text.disabled : color,
}));
