export const getOrdersRequestPayload = ({ page, rowsPerPage, search, filter }) => {
    return {
        page,
        rowsPerPage,
        search,
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        startPrice: filter?.startPrice,
        endPrice: filter?.endPrice,
        startQty: filter?.startQty,
        endQty: filter?.endQty,
        statuses: filter?.statuses,
        customers: filter?.customers,
        suppliers: filter?.suppliers,
        customerManagers: filter?.customerManagers,
        supplierManagers: filter?.supplierManagers,
        logos: filter?.logos,
        orders: filter?.orders,
        brands: filter?.brands,
        articles: filter?.articles,
        sortColumn: null,
        sortDirectionAsc: false,
    };
};
