import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, styled, tooltipClasses } from '@mui/material';
const ErrorTooltip = styled(({ className, ...props }) => _jsx(Tooltip, { ...props, arrow: true, classes: { popper: className } }))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.error.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
    },
}));
export { ErrorTooltip };
