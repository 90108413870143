import { svgIconClasses } from '@mui/material';
import { buttonAndInputBoxShadow, shadowSm } from '../shadows';
export const MuiButtonBase = {
    defaultProps: {
        disableRipple: true,
    },
    styleOverrides: {
        root: {
            borderWidth: 0,
            ':hover': {
                borderWidth: 0,
            },
            whiteSpace: 'nowrap',
        },
    },
};
export const MuiButton = {
    variants: [
        {
            props: { variant: 'soft' },
            style: ({ theme }) => ({
                color: theme.palette.primary.dark,
                boxShadow: ` 0 0 #0000,  0 0 #0000, ${shadowSm}`,
            }),
        },
        {
            props: { size: 'extraSmall' },
            style: { fontSize: '13px', padding: '.125rem .5rem' },
        },
    ],
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            height: 'fit-content',
            boxShadow: shadowSm,
            '&:hover': {
                boxShadow: shadowSm,
            },
            // TODO: Not working for some reason. Try in next versions of MUI
            // focusVisible: {
            // 	outlineColor: ownerState?.color ?? theme.palette.primary.dark,
            // 	outlineOffset: '2px',
            // 	outlineWidth: '2px',
            // 	outlineStyle: 'solid',
            // },
            '&.Mui-focusVisible': {
                // boxShadow: 'none',
                outlineColor: ownerState
                    ? ownerState.color === 'primary'
                        ? theme.palette.primary.dark
                        : ownerState.color === 'secondary'
                            ? theme.palette.secondary.dark
                            : ownerState.color === 'success'
                                ? theme.palette.success.dark
                                : ownerState.color === 'error'
                                    ? theme.palette.error.dark
                                    : ownerState.color === 'info'
                                        ? theme.palette.info.dark
                                        : ownerState.color === 'warning'
                                            ? theme.palette.warning.dark
                                            : theme.palette.primary.dark
                    : theme.palette.primary.dark,
                outlineOffset: '2px',
                outlineWidth: '2px',
                outlineStyle: 'solid',
            },
            // Bugfix: if we add extraSmall size for the button it won't work with variant 'text'
            '&.MuiButton-textSizeExtraSmall': {
                fontSize: '13px',
                padding: '.125rem .5rem',
            },
        }),
        sizeMedium: {
            lineHeight: '1.5rem',
            //borderRadius: '0.375rem',
            padding: '6px 16px',
        },
        sizeSmall: {
            //fontSize: '13px',
            padding: '.3125rem .5rem',
        },
        //outlined: {
        // outlined: ({ownerState, theme}) => ({
        // 	color: 'rgb(17, 24, 39)', //'text.primary',
        // 	borderWidth: 0,
        // 	boxShadow: buttonAndInputBoxShadow,
        // 	':hover': {
        // 		borderWidth: 0,
        // 		//backgroundColor: tokens('light').grey[50], //'rgb(249 250 251 / 1)', // TODO: to change on bg-50
        // 		backgroundColor:  tokens('light').grey[50], //'rgb(249 250 251 / 1)', // TODO: to change on bg-50
        // 		boxShadow: buttonAndInputBoxShadow,
        // 	}),
        // },
        text: ({ ownerState, theme }) => ({
            // boxShadow: ` 0 0 #0000,  0 0 #0000, ${shadowSm}`,
            // color: theme.palette.common.white,
            '&.Mui-disabled': {
                backgroundColor: theme.palette.background.default,
                opacity: 0.8,
            },
        }),
        textPrimary: ({ ownerState, theme }) => ({
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.lightest50,
            ':hover': {
                backgroundColor: theme.palette.primary.lightest,
            },
        }),
        textSecondary: ({ ownerState, theme }) => ({
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.lightest50,
            ':hover': {
                backgroundColor: theme.palette.secondary.lightest,
            },
        }),
        textSuccess: ({ ownerState, theme }) => ({
            color: theme.palette.success.dark,
            backgroundColor: theme.palette.success.lightest50,
            ':hover': {
                backgroundColor: theme.palette.success.lightest,
            },
        }),
        textInfo: ({ ownerState, theme }) => ({
            color: theme.palette.info.dark,
            backgroundColor: theme.palette.info.lightest50,
            ':hover': {
                backgroundColor: theme.palette.info.lightest,
            },
        }),
        textWarning: ({ ownerState, theme }) => ({
            color: theme.palette.warning.dark,
            backgroundColor: theme.palette.warning.lightest50,
            ':hover': {
                backgroundColor: theme.palette.warning.lightest,
            },
        }),
        textError: ({ ownerState, theme }) => ({
            color: theme.palette.error.dark,
            backgroundColor: theme.palette.error.backgroundLight,
            ':hover': {
                backgroundColor: theme.palette.error.lightest,
            },
        }),
        contained: ({ ownerState, theme }) => ({
            color: theme.palette.common.white,
        }),
        containedPrimary: ({ ownerState, theme }) => ({
            '&.Mui-disabled': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                opacity: '50%',
            },
        }),
        containedSecondary: ({ ownerState, theme }) => ({
            '&.Mui-disabled': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                opacity: '50%',
            },
        }),
        containedSuccess: ({ ownerState, theme }) => ({
            '&.Mui-disabled': {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.common.white,
                opacity: '50%',
            },
        }),
        containedWarning: ({ ownerState, theme }) => ({
            '&.Mui-disabled': {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.common.white,
                opacity: '50%',
            },
        }),
        containedError: ({ ownerState, theme }) => ({
            '&.Mui-disabled': {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
                opacity: '50%',
            },
        }),
        outlined: ({ ownerState, theme }) => ({
            //color: 'rgb(17, 24, 39)', //'text.primary',
            color: theme.palette.text.primary,
            backgroundColor: '#fff',
            //fontWeight: theme.typography.fontWeightBold,
            borderWidth: 0,
            boxShadow: buttonAndInputBoxShadow,
            ':hover': {
                borderWidth: 0,
                //backgroundColor: tokens('light').grey[50], //'rgb(249 250 251 / 1)', // TODO: to change on bg-50
                backgroundColor: theme.palette.grey[50], //'rgb(249 250 251 / 1)', // TODO: to change on bg-50
                boxShadow: buttonAndInputBoxShadow,
            },
            '&.Mui-disabled': {
                border: 0,
                opacity: 0.5,
            },
        }),
        // TODO: check if it had been fixed.
        // `icon:` Not working for some reason. Duplicated in iconSize[SML]
        icon: ({ ownerState, theme }) => ({
            [`.${svgIconClasses.root}`]: {
                color: theme.palette.grey[700],
            },
        }),
        iconSizeMedium: ({ ownerState, theme }) => ({
            [`.${svgIconClasses.root}`]: {
                width: '1.125rem',
                color: theme.palette.grey[600],
            },
        }),
    },
};
