import { Backdrop, TableCell, styled } from '@mui/material';
export const BackdropStyled = styled(Backdrop, {
    shouldForwardProp: (prop) => prop !== 'dialog' && prop !== 'corners',
})(({ theme, dialog, corners = 'rounded' }) => ({
    color: dialog ? theme.palette.primary.main : undefined,
    backgroundColor: dialog ? 'rgba(0, 0, 0, 0.1)' : undefined,
    borderRadius: dialog || corners === 'straight' ? 0 : `${theme.shape.borderRadius}px`,
    // backdropFilter: 'blur(1px)',
    // background: 'transparentize(white, 0.8)',
    // backdropFilter: 'opacity(20%)',
}));
export const InvisibleTableCell = styled(TableCell)({
    padding: 0,
    height: 0,
    border: 'none',
});
