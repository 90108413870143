// TODO: a similar interface used in admin/reclamations , check...
export var BlockReason;
(function (BlockReason) {
    BlockReason[BlockReason["Defect"] = 1] = "Defect";
    BlockReason[BlockReason["Counterfeit"] = 2] = "Counterfeit";
    BlockReason[BlockReason["Undersupply"] = 3] = "Undersupply";
    BlockReason[BlockReason["Loss"] = 4] = "Loss";
    BlockReason[BlockReason["Refusal"] = 5] = "Refusal";
    BlockReason[BlockReason["Refund"] = 6] = "Refund";
})(BlockReason || (BlockReason = {}));
