import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
const shipmentInitialState = {
    readOnlyMode: false,
    shipmentEditDialog: {
        open: false,
        outDocumentId: undefined,
        documentNum: undefined,
        deletedStickerIds: [],
    },
    availableStickersDialog: {
        open: false,
    },
    groups: [],
    currentDeliveryBoxId: undefined,
    addedStickerIds: [],
    period: null,
};
const useShipmentStore = create()(immer((set) => ({
    ...shipmentInitialState,
    openShipmentEditDialog: (readOnlyMode, outDocumentId, documentNumber) => set((state) => {
        state.readOnlyMode = readOnlyMode;
        state.shipmentEditDialog.open = true;
        state.shipmentEditDialog.outDocumentId = outDocumentId;
        state.shipmentEditDialog.documentNum = documentNumber;
    }),
    closeShipmentEditDialog: () => set(shipmentInitialState),
    openAvailableStickersDialog: () => set((state) => {
        state.availableStickersDialog.open = true;
    }),
    closeAvailableStickersDialog: () => set((state) => {
        state.availableStickersDialog = shipmentInitialState.availableStickersDialog;
    }),
    setGroups: (groups) => set((state) => {
        state.groups = groups;
        state.currentDeliveryBoxId = groups.at(0)?.at(0);
    }),
    setCurrentDeliveryBox: (deliveryBoxId) => set((state) => {
        state.currentDeliveryBoxId = deliveryBoxId;
    }),
    markStickerAsDeleted: (stickerId) => set((state) => {
        if (!state.shipmentEditDialog.deletedStickerIds.includes(stickerId)) {
            state.shipmentEditDialog.deletedStickerIds = [...state.shipmentEditDialog.deletedStickerIds, stickerId];
        }
    }),
    removeStickerFromDeleted: (stickerId) => set((state) => {
        if (state.shipmentEditDialog.deletedStickerIds.includes(stickerId)) {
            state.shipmentEditDialog.deletedStickerIds = state.shipmentEditDialog.deletedStickerIds.filter((id) => id !== stickerId);
        }
    }),
    addStickersToCurrentBox: (stickerItemIds) => set((state) => {
        if (!state.currentDeliveryBoxId)
            throw new Error('Current delivery box is not set!');
        if (state.addedStickerIds.some((addedSticker) => stickerItemIds.includes(addedSticker.stickerItemId)))
            throw new Error('StickerItem with the same "stickerItemId" already exists in addedStickers pool!');
        state.addedStickerIds = [
            ...state.addedStickerIds,
            ...stickerItemIds.map((stickerItemId) => ({
                stickerItemId,
                deliveryBoxId: state.currentDeliveryBoxId,
            })),
        ];
    }),
    returnAddedStickerToAvailable: (stickerItemId) => set((state) => {
        if (!state.addedStickerIds.some((sticker) => sticker.stickerItemId === stickerItemId))
            return;
        state.addedStickerIds = state.addedStickerIds.filter((sticker) => sticker.stickerItemId !== stickerItemId);
    }),
    setPeriod: (value) => set((state) => {
        state.period = value;
    }),
})));
export { useShipmentStore };
