import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
// export interface TransactionsResponse {
// 	isNext: boolean;
// 	page: number;
// 	payments: ITransaction[];
// 	[x: string]: any; // TODO: not working (no restriction)
// }
export const generalSettingsApi = createApi({
    reducerPath: 'api/generalSettings',
    tagTypes: ['GeneralSettings'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        // getCustomers: builder.query<ICustomer[], IGetDataListQueryArguments>({
        // 	query: ({ limit = 100, offset, search }) => ({
        // 		url: 'Customer',
        // 		params: { limit, offset, search },
        // 		method: 'GET',
        // 		//body: JSON.stringify(credentials),
        // 	}),
        // 	// 		export const mapUsers = (users: UserListItem[]) =>
        // 	// users.map((u) => ({
        // 	// 	...u,
        // 	// 	modifiedLocalTime: parseDateTime(u.modifiedLocalTime)
        // 	// }));
        // 	transformResponse: (response: ICustomer[]) => response.map((customer) => ({ ...customer, createDate: new Date(customer.createDate) })),
        // 	//providesTags: (result) => ['Customers'],
        // 	//providesTags: ['Customers'],
        // 	// providesTags: (result) =>
        // 	// 	// is result available?
        // 	// 	result
        // 	// 		? // successful query
        // 	// 		  [...result.map(({ customerId }) => ({ type: 'Customers', customerId } as const))]
        // 	// 		: // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
        // 	// 		  ['Customers'],
        // }),
        // getCustomer: builder.query<ICustomer, number>({
        // 	query: (id) => ({
        // 		url: `Customer/${id}`,
        // 		method: 'GET',
        // 	}),
        // 	transformResponse: (response: ICustomer) => ({
        // 		...response,
        // 		createDate: new Date(response.createDate),
        // 		changeDate: new Date(response.changeDate),
        // 		birthday: response.birthday ? new Date(response.birthday) : null,
        // 		contractDate: new Date(response.contractDate),
        // 	}),
        // 	providesTags: (result, error, id) => [{ type: 'Customers', id }],
        // }),
        getGeneralSettings: builder.query({
            query: () => ({
                method: 'GET',
                url: 'generalSettings',
            }),
            //transformResponse: (response: ICustomer[]) => response.map((customer) => ({ ...customer, createDate: new Date(customer.createDate) })),
        }),
    }),
});
export const { useGetGeneralSettingsQuery } = generalSettingsApi;
