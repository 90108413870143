import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Chip, TextField as MuiTextField, createFilterOptions } from '@mui/material';
import { IconContainer } from './ServerSearchAutocompleteSimple.styled';
import { SpinnerSvgIcon } from '../SpinnerSvgIcon';
import { engRusLetterOrNumberOnlyRegex } from 'core/utils/validation';
import { t } from 'shared/translations';
import { useDebounceValue } from 'usehooks-ts';
const minimumCharsToFetch = 2;
const filter = createFilterOptions();
//const ServerSearchAutocomplete = React.forwardRef(<T, >({ getQuery, onChange, value, labelProperty, isError: isErrorState, ...props }: IProps<T>, ref: React.Ref<T>) => {
/**
 * Sends remote request with search string and fills options with request response.
 * Should be used when just **an array of string** is returned as `getQuery` result.
 *
 * @param multiple forces component to have Chips, otherwise text is used.
 * @param getQuery RTK `useQuery` that makes remote search and returns an array of string.
 * @param allowCreateOption Optional. Adds the single *Add newValue* option if no option that satisfies searchTerm's condition is found.
 *
 * @returns an array with string values
 */
const ServerSearchAutocompleteSimple = ({ value: autocompleteValue, multiple, allowCreateOption, refetchOnMountOrArgChange, isError: isErrorState, getQuery, onChange, ...autocompleteRestProps }) => {
    // TODO: to delete later
    // const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchTerm, setSearchTerm] = useDebounceValue('', 300);
    // TODO: use isQueryError
    const { data, isFetching, isError: isQueryError, } = getQuery({ search: searchTerm }, { skip: searchTerm.length <= minimumCharsToFetch, refetchOnMountOrArgChange });
    const handleInputKeyDown = (event) => {
        // `"` symbol must be forbidden, since it used to determine if value is being newly created value.
        const isAllowed = engRusLetterOrNumberOnlyRegex.test(event.key) || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab';
        if (!isAllowed)
            event.preventDefault();
    };
    const filterOptionsForAllowCreate = (options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && inputValue.length > 2 && !isExisting && !isFetching) {
            // Important!
            // If `Add "xxx"` here is gonna be changed fo some reason, it must be handled in `onChange` handler, since
            // `truncatedArticleClearValue` calculations is based on this syntax.
            filtered.push(`${t('server.search.autocomplete.simple.new.value.add.prefix')} "${inputValue}"`);
        }
        return filtered;
    };
    return (_jsx(Autocomplete, { multiple: multiple, 
        // selectOnFocus
        // When text is typed by user but option has still not been selected, removes the input value on blur.
        // User should add a new value by selecting appropriate option if new value is required.
        clearOnBlur: true, handleHomeEndKeys: true, 
        // Returned to fix Mui console warning about item is not in list (None of the options match with `"LYKMC"`).
        // It also needed to allow adding new value (`allowCreateOption = true`).
        freeSolo: true, filterSelectedOptions: true, ...autocompleteRestProps, 
        // Works only this way.
        // Not like `value={autocompleteValue || multiple ? undefined : null}`
        value: multiple ? autocompleteValue || undefined : autocompleteValue || null, clearText: t('delete.all'), 
        // options={data || []}
        // `searchTerm.length <= minimumCharsToFetch` handles the case when after all chars has been deleted options is still having stale results.
        options: searchTerm.length <= minimumCharsToFetch ? [] : data || [], 
        // Fix console error "... `getOptionLabel` method of Autocomplete returned object (BMW) instead of a string ["BMW"] ..."
        // Only reproduced when multiple={false}
        getOptionLabel: (option) => option || '', loading: isFetching, loadingText: t('loading.ellipsis.label'), 
        // filterOptions={(options) => options} // Mui recommends for async search.
        filterOptions: allowCreateOption ? filterOptionsForAllowCreate : (options) => options, 
        // `multiple` prop forces component to have Chips, otherwise text is used.
        renderTags: multiple
            ? (value, getTagProps, ownerState) => {
                return value.map((option, index) => {
                    // Key value should be passed within `key` prop only. When it used through spread operator it leads to react console error.
                    const { key, ...tagProps } = getTagProps({ index });
                    return _jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: option, ...tagProps }, key);
                });
            }
            : undefined, renderInput: (params) => (
        // Only Mui TextFiled works inside Autocomplete.
        _jsx(MuiTextField, { ...params, size: "small", 
            // Using `autocompleteRestProps.placeholder` instead of just placeholder, since Autocomplete should receive this prop either.
            // Otherwise it leads to a console bug.
            placeholder: autocompleteRestProps.placeholder ?? t('search.label'), error: isErrorState, sx: multiple ? undefined : { '.MuiOutlinedInput-input': { height: '19px' } }, InputProps: {
                onKeyDown: handleInputKeyDown,
                ...params.InputProps,
                endAdornment: (_jsxs(_Fragment, { children: [isFetching ? (_jsx(IconContainer, { children: _jsx(SpinnerSvgIcon, { size: "sm" }) })) : null, params.InputProps.endAdornment] })),
            } })), 
        // Should just be...
        // onChange={(event, value) => {
        // 	onChange(value);
        // }}
        // ..., but a value creation is handled this way
        onChange: (event, value) => {
            // If the selected value is a newly created value like `Add "xxx"`.
            if (!multiple && allowCreateOption && isNewlyCreated(value)) {
                const newValue = value;
                const truncatedArticleClearValue = newValue.slice(newValue.indexOf('"') + 1, newValue.length - 1);
                onChange(truncatedArticleClearValue);
                return;
            }
            onChange(value);
        }, onInputChange: (event, value, reason) => {
            if (!multiple && allowCreateOption && isNewlyCreated(value)) {
                return;
            }
            setSearchTerm(value.trim());
        }, noOptionsText: t('please.enter.the.first.two.letters.to.search') }));
};
const isNewlyCreated = (value) => {
    return value && value.length > 4 && value.includes(' "');
};
export { ServerSearchAutocompleteSimple };
