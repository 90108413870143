import { maxServerErrorMessageLengthLimit } from 'core/constants';
import { truncate } from 'core/utils/strings';
const getErrorMessage = (error) => {
    let message = 'Something went wrong.';
    if (!error)
        return message;
    if (error instanceof Error) {
        message = error.message;
    }
    else if (typeof error === 'object' && 'message' in error) {
        message = String(error.message);
    }
    else if (typeof error === 'object' && 'Message' in error) {
        message = String(error.Message);
    }
    else if (typeof error === 'object' && 'error' in error) {
        message = String(error.error);
    }
    else if (typeof error === 'string') {
        message = error;
    }
    // This case is very rare. Backend just returns an array of exceptions.
    // TODO: later to delete when new backend is ready
    // [
    //   {
    // 	  "exception": null,
    // 	  "errorMessage": "Error converting value {null} to type 'System.Int32'. Path 'pricelistSettings.port', line 1, position 386."
    //   }
    // ]
    else if (error instanceof Array && 'errorMessage' in error[0]) {
        message = error[0].errorMessage;
    }
    return message;
};
export const getQueryErrorMessage = (error) => {
    let message = 'Something went wrong.';
    // TODO: to delete or to use if error can be 'unknown' type: if (!error || typeof error !== 'object' || typeof error !== 'string') return message;
    if (!error)
        return message;
    if ('data' in error) {
        message = getErrorMessage(error.data);
    }
    else if ('message' in error) {
        message = error.message || message;
    }
    return message;
};
export const getErrorFromException = (error) => truncateErrorMessage(getQueryErrorMessage(error)) ?? '';
export const truncateErrorMessage = (message) => truncate(message, maxServerErrorMessageLengthLimit);
