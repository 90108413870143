import { object, string } from 'yup';
import { strongEmail } from 'core/utils/validation';
const schema = object({
    //email: string().matches(emailRegex, 'Invalid email address').required(),
    email: strongEmail,
    password: string().min(6, 'Password must be at least 6 characters long.').max(50).required(),
});
const defaultValues = {
    email: '',
    password: '',
};
export { schema, defaultValues };
