export const mapHeightEditMode = 320;
export const mapHeightViewMode = 400;
export const mapColumnCount = 999;
export const mapRowCount = 99;
export const mapCellSize = 30;
export const mapBorderWidth = 1;
export const defaultBrowserScrollerWidth = 17;
// TODO: check if it is right (- defaultBrowserScrollerWidth)
export const mapCanvasWidth = mapCellSize * mapColumnCount - defaultBrowserScrollerWidth;
export const mapCanvasHeight = mapCellSize * mapRowCount - defaultBrowserScrollerWidth;
export const mapPreviewHeight = 150;
export const mapPreviewWidthInit = mapPreviewHeight * 3;
export const mapPreviewGridSize = 5;
export const mapPreviewBorderWidth = 1;
