import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    userDeactivationDialog: {
        open: false,
        user: undefined,
    },
};
const identitySlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {
        openUserDeactivationDialog: (state, action) => {
            state.userDeactivationDialog.open = true;
            //state.userDeactivationDialog.data = { ...action.payload };
            state.userDeactivationDialog.user = {
                email: action.payload.userEmail,
                fullName: action.payload.userFullName,
                userId: action.payload.userId,
                avatar: action.payload.userAvatar,
            };
        },
        closeUserDeactivationDialog: (state) => {
            state.userDeactivationDialog.open = false;
        },
    },
});
export const { actions: identityActions, reducer: identityStateReducer } = identitySlice;
