/** Encapsulates native `localStorage` and `sessionStorage` calls and forces to use typed keys.
 *
 * Also fires events to `useLocalStorage` and `useSessionStorage` hooks to refresh their read values.
 */
const createStorageBase = (type) => {
    const storage = type === 'local' ? localStorage : sessionStorage;
    const result = {
        setItem: (key, value) => {
            storage.setItem(key, value);
            // Hook data update
            window.dispatchEvent(new StorageEvent(`${type}-storage`, { key }));
        },
        getItem: (key) => {
            return storage.getItem(key);
        },
        removeItem: (key) => {
            storage.removeItem(key);
            // Hook data update
            window.dispatchEvent(new StorageEvent(`${type}-storage`, { key }));
        },
    };
    return result;
};
export { createStorageBase };
