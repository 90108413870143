// TODO: to delete
// export const PriceListFileType = {
// 	1: 'txt',
// 	2: 'csv',
// 	3: 'xlsx',
// 	4: 'xls',
// };
export var PriceListFileType;
(function (PriceListFileType) {
    PriceListFileType[PriceListFileType["Txt"] = 1] = "Txt";
    PriceListFileType[PriceListFileType["Csv"] = 2] = "Csv";
    PriceListFileType[PriceListFileType["Xlsx"] = 3] = "Xlsx";
    PriceListFileType[PriceListFileType["Xls"] = 4] = "Xls";
})(PriceListFileType || (PriceListFileType = {}));
