import { addDays, endOfDay, startOfDay, subDays, subMonths, subYears } from 'date-fns';
export const getPeriodDateRangeValues = (period) => {
    const now = new Date();
    const dayFrom = period === 'day'
        ? now
        : period === 'week'
            ? // ? subWeeks(now, 1) // 8 days
                subDays(now, 6) // last 7 days
            : period === 'month'
                ? // ? subMonths(now, 1) // same day (from 3rd to 3rd) (31 days for 31-days month)
                    // subDays(now, 29) // last 30 days
                    addDays(subMonths(now, 1), 1) // from 4th to 3rd (31 days for 31-days month)
                : period === 'year'
                    ? addDays(subYears(now, 1), 1)
                    : // default value for `custom` period - last two weeks
                        subDays(new Date(), 13);
    return {
        periodDateFrom: startOfDay(dayFrom),
        periodDateTo: endOfDay(now),
    };
};
