import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, ClickAwayListener, Divider, FormControlLabel, Paper, Popper, Typography, createFilterOptions } from '@mui/material';
import { useController } from 'react-hook-form';
import { FilterTextField, PaperHead, SelectAllCheckbox, SelectAllCheckboxContainer, StyledAutocompletePopper, optionStyle, } from './CheckboxSelect.styled';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Checkbox } from 'core/ui';
import { CheckboxSelectRootButton } from './CheckboxSelectRootButton';
import { t } from 'shared/translations';
/**
 * Select with checkboxes and one SelectAll checkbox that checks or unchecks only filtered options.
 *
 * Uses MuiAutocomplete under the hood.
 *
 * @param options Options list
 * @param keyField The name of the field that contains item key
 * @param labelField The name of the field that contains item label
 */
const CheckboxSelect = ({ options, keyField, labelField, ...controllerProps }) => {
    const [anchorElement, setAnchorElement] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    // Using useState instead of useRef here forces infinite rerenders on setFilterOptions.
    const filteredOptionIds = useRef([]);
    // const [pendingValue, setPendingValue] = useState<LabelType[]>([]);
    const { field: { value, onChange, ...field }, fieldState: { invalid, error }, } = useController(controllerProps);
    const handleClose = () => {
        // setValue(pendingValue);
        if (anchorElement) {
            anchorElement.focus();
        }
        setAnchorElement(null);
    };
    // TODO: to delete later. The reason of multiple setSelectAll calls (recursive).
    // const onSelectAllCheckboxClick = useCallback(() => {
    // 	setSelectAll((previousSelectAll) => {
    // 		// TODO: to delete
    // 		// console.log('value', value);
    // 		// console.log('filteredOptionIds', filteredOptionIds.current);
    // 		// console.log('previousSelectAll', previousSelectAll);
    // 		// setTimeout is used to prevent console error `Cannot update a component while rendering a different component`
    // 		setTimeout(() => {
    // 			if (previousSelectAll) {
    // 				onChange(value.filter((id: number) => !filteredOptionIds.current.some((optionId) => optionId === id)));
    // 			} else {
    // 				const idsToAdd = filteredOptionIds.current.filter((optionId) => !(value ?? []).some((id: number) => id === optionId));
    // 				onChange([...(value ?? []), ...idsToAdd]);
    // 			}
    // 		}, 50);
    // 		return !previousSelectAll;
    // 	});
    const onSelectAllCheckboxClick = useCallback(() => {
        if (selectAll) {
            onChange(value.filter((id) => !filteredOptionIds.current.some((optionId) => optionId === id)));
        }
        else {
            const idsToAdd = filteredOptionIds.current.filter((optionId) => !(value ?? []).some((id) => id === optionId));
            onChange([...(value ?? []), ...idsToAdd]);
        }
    }, [value, filteredOptionIds.current, onChange, setSelectAll]);
    const filter = createFilterOptions();
    const selectedValues = useMemo(() => (value ? options.filter((option) => value.includes(option[keyField])) : []), [options, value]);
    return (_jsxs(_Fragment, { children: [_jsx(CheckboxSelectRootButton, { selectedItemsCount: value?.length, onClick: (event) => setAnchorElement(event.currentTarget), onClearButtonClick: () => onChange([]) }), _jsx(Popper, { disablePortal // allows width to be equal to container
                : true, 
                // keepMounted
                open: Boolean(anchorElement), anchorEl: anchorElement, placement: "bottom-start", sx: { width: '100%' }, modifiers: [
                    {
                        name: 'offset',
                        options: { offset: [0, 4] }, // 4px - need to be 0.25rem
                    },
                ], children: _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(Paper, { sx: ({ shadows }) => ({ boxShadow: shadows[11], borderRadius: '8px' }), children: _jsx(Autocomplete, { open: true, multiple: true, onClose: (_, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }, 
                            // value={pendingValue}
                            value: selectedValues, 
                            // onChange={(event, newValue, reason) => {
                            // 	if (
                            // 		event.type === 'keydown' &&
                            // 		((event as React.KeyboardEvent).key === 'Backspace' || (event as React.KeyboardEvent).key === 'Delete') &&
                            // 		reason === 'removeOption'
                            // 	) {
                            // 		return;
                            // 	}
                            // 	// setPendingValue(newValue);
                            // 	onChange(newValue);
                            // }}
                            onChange: (event, newValue, reason) => {
                                const selectedIds = newValue.map((item) => item[keyField]);
                                if (reason === 'clear' || reason === 'removeOption')
                                    setSelectAll(false);
                                if (reason === 'selectOption' && filteredOptionIds.current.every((optionId) => selectedIds.includes(optionId)))
                                    setSelectAll(true);
                                onChange(selectedIds);
                            }, disableCloseOnSelect: true, PopperComponent: PopperComponent, renderTags: () => null, noOptionsText: _jsx(Typography, { textAlign: "center", children: t('nothing.found.label') }), renderOption: (props, option, { selected }) => (_createElement("li", { ...props, key: option[keyField], style: optionStyle },
                                _jsx(Checkbox, { checked: selected, sx: { marginRight: '.5rem' }, tabIndex: -1 }),
                                option[labelField])), options: options ?? [], getOptionLabel: (option) => option[labelField], filterOptions: (options, state) => {
                                const filtered = filter(options, state);
                                filteredOptionIds.current = filtered.map((option) => option[keyField]);
                                const allFilteredAreChecked = filteredOptionIds.current.every((optionId) => value.includes(optionId));
                                if (selectAll !== allFilteredAreChecked) {
                                    // setTimeout is used to prevent console error `Cannot update a component while rendering a different component`
                                    setTimeout(() => {
                                        setSelectAll(allFilteredAreChecked);
                                    }, 10);
                                }
                                return filtered;
                            }, 
                            // groupBy={(option) => option.city}
                            defaultValue: [], inputValue: searchInputValue, onInputChange: (_, value, reason) => reason !== 'reset' && setSearchInputValue(value), renderInput: (params) => (_jsxs(PaperHead, { children: [_jsx(FilterTextField, { autoFocus: true, placeholder: t('filter.label'), ref: params.InputProps.ref, 
                                        // type="search" (that brings native clear xMark to input[type=text]) is probably not used here since it's overridden by custom clear button
                                        inputProps: { ...params.inputProps, type: 'search' } }), _jsx(SelectAllCheckboxContainer
                                    //onMouseDown={(event) => event.preventDefault()} // prevent blur
                                    , { children: filteredOptionIds.current.length > 0 && (_jsx(FormControlLabel
                                        // onClick handler used instead of Checkbox onChange since there happens bug when click not on checkbox icon itself but on label.
                                        , { 
                                            // onClick handler used instead of Checkbox onChange since there happens bug when click not on checkbox icon itself but on label.
                                            onClick: (event) => {
                                                event.preventDefault(); // prevent blur
                                                onSelectAllCheckboxClick();
                                            }, label: t('select.all.label'), control: _jsx(SelectAllCheckbox, { checked: selectAll }) })) }), _jsx(Divider, {})] })) }) }) }) })] }));
};
const PopperComponent = (props) => {
    const { disablePortal, anchorEl, open, ...other } = props;
    return _jsx(StyledAutocompletePopper, { ...other, style: { borderRadius: 0, boxShadow: 'none' } });
};
export { CheckboxSelect };
