import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { maxServerErrorMessageLengthLimit } from 'core/constants';
import { truncate } from 'core/utils/strings';
const ServerError = ({ error }) => {
    let errorMessage = 'Unknown server error';
    const data = error.data;
    if (data) {
        if (data.error) {
            errorMessage = data.error;
        }
        else if (data.StatusCode) {
            const statusCode = data.StatusCode;
            // if (statusCode === 500) {
            // 	errorMessage = t('internal.server.error');
            // } else {
            if (data.Message)
                errorMessage = `Error: ${data.Message}. StatusCode: ${statusCode}`;
            // This 'else if' may be mistake, check later with new backend
            else if (error.Message)
                errorMessage = `Error: ${error.Message}. StatusCode: ${statusCode}`;
            // }
            // To be deleted (request with data and)
        }
        else if (error.message) {
            errorMessage = error.message;
        }
    }
    return (_jsx(Box, { display: "flex", pt: 2, px: 1, children: _jsx(Typography, { variant: "body1", sx: { color: 'error.default' }, children: truncate(errorMessage, maxServerErrorMessageLengthLimit) }) }));
};
export { ServerError };
