import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar as MuiAvatar } from '@mui/material';
/**
 *  **Global Parts** component
 *
 * Use *image* prop instead of *src* for base64 encoded string.
 *
 * @param src should be used for static paths
 * @param image should be used for base64 string images
 *
 * Note:
 * *Image* prop used to avoid bad request that occurs when *src* prop has broken image (for example `data:image/jpeg;base64, undefined`).
 * Also *image* prop prepends `data:image/jpeg;base64, ` to base64string image.
 *
 * Note:
 * Default behavior of MuiAvatar, when empty *src* prop leads to a **generic avatar icon** instead of **the first letter of the *alt* text**,
 * is overridden by manually created first letter from the *alt* prop.
 */
const Avatar = ({ src, image, ...props }) => {
    if (!src && !image && props.alt)
        return _jsx(MuiAvatar, { ...props, children: props.alt.at(0) });
    return _jsx(MuiAvatar, { ...props, src: image ? `data:image/jpeg;base64, ${image}` : src });
};
export { Avatar };
