import { jsx as _jsx } from "react/jsx-runtime";
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { forwardRef } from 'react';
const datePickerCompactMaxWidth = '180px';
const datePickerDenseMaxWidth = '9.1rem';
// const DatePicker = forwardRef(({ invalid, compactWidth, ...props }: Props<Date> & OwnProps, ref: React.Ref<HTMLInputElement>) => {
const DatePicker = forwardRef(({ invalid, width, ...props }, ref) => {
    // const sxCompactWidth: SxProps<Theme> = compactWidth ? { width: datePickerCompactMaxWidth, maxWidth: datePickerCompactMaxWidth } : {};
    const sxCompactWidth = width === 'compact'
        ? { width: datePickerCompactMaxWidth, maxWidth: datePickerCompactMaxWidth }
        : width === 'dense'
            ? { width: datePickerDenseMaxWidth, maxWidth: datePickerDenseMaxWidth }
            : { width: width };
    return (
    // <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
    _jsx(MuiDatePicker, { ...props, inputRef: ref, slotProps: {
            ...props.slotProps,
            popper: {
                sx: (theme) => ({
                    '.MuiPickersPopper-paper': {
                        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px',
                    },
                }),
            },
            textField: {
                error: invalid,
            },
        }, 
        // @ts-ignore
        sx: {
            ...props.sx,
            ...sxCompactWidth, // this is the reason of @ts-ignore statement on sx prop (try comment out).
            '.MuiOutlinedInput-input': { color: ({ palette }) => palette.text.primary },
            '.MuiSvgIcon-root': { fontSize: '1.25rem' },
        } })
    // <MuiDatePicker {...props} slots={{ field: OutlinedInput, textField: <TextField ref={ref} /> }} />
    // </LocalizationProvider>
    );
});
export { DatePicker };
