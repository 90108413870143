import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useContext, useEffect } from 'react';
import { ActionIconsContext } from '../ActionIcons.context';
import { IconButton } from '@mui/material';
import { SpinnerSvgIcon } from 'core/ui';
import { t } from 'shared/translations';
export const SpinnerActionIcon = forwardRef(({ size, title, disabled, color, onClick, ...props }, ref) => {
    const containerContext = useContext(ActionIconsContext);
    useEffect(() => {
        if (!containerContext) {
            throw new Error('SpinnerActionIcon must be used as a child of ActionIcons component.');
        }
    });
    const { iconSize } = containerContext ?? {};
    return (_jsx(IconButton, { tabIndex: -1, ref: ref, ...props, title: title ?? t('waiting.for.the.request.to.complete'), disableRipple: true, sx: { ':hover': { cursor: 'default' } }, children: _jsx(SpinnerSvgIcon, { size: size ?? iconSize }) }));
});
