export const MuiPaper = {
    defaultProps: {
        elevation: 2,
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            borderRadius: '8px',
        }),
    },
};
