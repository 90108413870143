export const periodDateRangeDeserializer = (initialValue) => (value) => {
    let parsed;
    try {
        parsed = JSON.parse(value);
        return {
            period: parsed.period,
            periodDateFrom: parsed.periodDateFrom ? new Date(parsed.periodDateFrom) : null,
            periodDateTo: parsed.periodDateTo ? new Date(parsed.periodDateTo) : null,
        };
    }
    catch (error) {
        console.error('Error parsing JSON:', error);
        return initialValue; // Return initialValue if parsing fails
    }
};
