import { useSessionStorage } from 'usehooks-ts';
/**
 * Custom hook that uses the [`sessionStorage API`](https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage) to persist state across page reloads.
 *
 * DO NOT USE THE HOOK DIRECTLY. Use derived hooks for *Core*, *Admin app* or *Warehouse app* instead.
 *
 * @template T - The type of the state to be stored in session storage.
 * @template K - The fixed constant of the key to be used for instance hooks.
 * @param {string} key - The key under which the value will be stored in session storage.
 * @param {T | (() => T)} initialValue - The initial value of the state or a function that returns the initial value.
 * @param {?UseSessionStorageOptions<T>} [options] - Options for customizing the behavior of serialization and deserialization (optional).
 * @returns {[T, Dispatch<SetStateAction<T>>, () => void]} A tuple containing the stored value, a function to set the value and a function to remove the key from storage.
 * @example
 * ```tsx
 * // Access the `count` value, the `setCount` function to update it and `removeCount` function to remove the key from storage.
 * const [count, setCount, removeCount] = useSessionStorage('count', 0);
 * // Acts like a native useState from react.
 * setCount(prevValue => prevValue + 1)
 * ```
 */
export const useSessionStorageBase = (key, initialValue, options) => useSessionStorage(key, initialValue, options);
