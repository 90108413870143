/**
 * Converts data to editable form.
 *
 * @param priceList
 * @param priceListsForExport
 * @returns
 */
export const convertToEditableFormModel = (priceListsForExport, priceList) => {
    const sortedAndCombined = priceListsForExport
        .map((priceListForExport) => {
        const includedPriceList = priceList.includedPricelists.find((item) => item.pricelistId === priceListForExport.pricelistId);
        return {
            ...priceListForExport,
            checked: Boolean(includedPriceList),
            markup: includedPriceList?.markup ?? null,
        };
    })
        .sort((a, b) => 
    // The same as below
    // if (a.checked !== b.checked) return a.checked ? -1 : 1;
    // return (a.name || '').localeCompare(b.name || '');
    +b.checked - +a.checked || (a.name || '').localeCompare(b.name || ''));
    // Removing initial `includedPricelists` field, that is gonna be replaced with `includedPriceListsCombined` below.
    const { includedPricelists, ...priceListEditable } = priceList;
    return {
        ...priceListEditable,
        includedPriceListsCombined: sortedAndCombined,
        // Times coming as 10:30:00 instead of 10:30. Convert.
        scheduleTime: priceList.scheduleTime.some((el) => el.length > 5) ? priceList.scheduleTime.map((el) => el.slice(0, 5)) : priceList.scheduleTime,
    };
};
