import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, styled } from '@mui/material';
export const FileUploadButton = ({ label, onChange }) => {
    const onFileInputChange = (e) => {
        const file = e.target?.files?.[0];
        onChange(file || null);
    };
    return (_jsxs(Button, { component: "label", variant: "outlined", children: [label, _jsx(VisuallyHiddenInput, { type: "file", onChange: onFileInputChange, accept: "image/png,image/jpeg,image/gif" })] }));
};
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
