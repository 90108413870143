import { formatDate } from 'date-fns';
/**
 * Formats date value to string in a way the order filter can accept
 *
 * Btw:
 * Converts **utc** date as string "2024-06-01T21:00:00.000Z" to **local** "2024-06-02T00:00:00" as a result.
 *
 * @param {Date} date
 * @returns
 */
export const formatDateForOrdersFilter = (date) => formatDate(date, `yyyy-MM-dd'T'HH:mm:ss`);
