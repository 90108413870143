export var StockType;
(function (StockType) {
    StockType[StockType["Stock"] = 1] = "Stock";
    StockType[StockType["Crossdocking"] = 2] = "Crossdocking";
    StockType[StockType["Transfer"] = 3] = "Transfer";
})(StockType || (StockType = {}));
// TODO:!! and check if Transfer works everywhere
// enum StockType {
// 	Type1 = 1,
// 	Crossdocking = 2,
// }
