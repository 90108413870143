export const MuiRadio = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            height: '36px',
            width: '36px',
            // Remove Mui native svg's.
            span: {
                display: 'none',
            },
            'input[type="radio"]': {
                position: 'absolute',
                top: '10px',
                left: '10px',
                opacity: 1,
                color: theme.palette.primary.dark,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: theme.palette.neutral.light,
                borderRadius: '100%',
                appearance: 'none',
                padding: 0,
                display: 'inline-block',
                verticalAlign: 'middle',
                backgroundOrigin: 'border-box',
                userSelect: 'none',
                flexShrink: 0,
                height: '1rem',
                width: '1rem',
                '&:checked': {
                    borderColor: 'transparent',
                    backgroundColor: theme.palette.primary.dark,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")`,
                    '.Mui-disabled &': {
                        backgroundColor: theme.palette.text.disabled,
                    },
                },
                '&:focus': {
                    outline: '2px solid transparent',
                    outlineOffset: '2px',
                    boxShadow: `${theme.palette.background.paper} 0px 0px 0px 2px, ${theme.palette.primary.dark} 0px 0px 0px 4px, ${theme.palette.common.black} 0px 0px 0px 0px`,
                },
            },
        }),
    },
};
