const font = ['Inter var', 'Roboto', 'Arial'].join(', ');
export const typography = {
    fontFamily: font,
    //},
    //fontSize: 14, // same as MUI defaults
    //fontSize: 16, // same as Tailwind base
    //htmlFontSize: 18,
    //htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        //fontSize: 40,
        fontSize: '2rem',
    },
    h2: {
        //fontSize: 32,
        fontSize: '1.75rem',
    },
    h3: {
        //fontSize: 24,
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    h4: {
        //fontSize: 20,
        fontSize: '1.25rem',
    },
    h5: {
        //fontSize: 15,
        fontSize: '1.125rem',
    },
    h6: {
        //fontSize: 14,
        fontSize: '1rem',
        lineHeight: '1.3rem', // To handle dialog muted text in one line with dialog header text.
    },
    h7: {
        //fontSize: 14,
        fontSize: '.9375rem',
        lineHeight: '1.6rem', // To handle dialog muted text in one line with dialog header text.
        fontWeight: 500,
        marginBottom: '.25rem',
    },
    // subtitle1
    // subtitle2
    // body0: {
    // 	fontSize: '6rem',
    // },
    body1: {
        fontSize: '.875rem', // 14px
        color: 'rgb(17, 24, 39)',
        opacity: 1,
        //fontSize: '1rem',
        //fontSize: '0.9375rem',
    },
    body2: {
        fontSize: '.9375rem', // 15px
        //fontSize: '.875rem',
        color: 'rgb(17, 24, 39)',
        opacity: 1,
        lineHeight: '1.5',
    },
    // body2
    // button
    //button: { fontSize: '1rem' },
    button: { textTransform: 'none' },
    caption: {
        fontSize: '.8125rem', // 13px
    },
    helper: {
        fontSize: '.75rem', // 12px
    },
    // overline
    inherit: {
        fontSize: 'inherit',
    },
};
