import { styled } from '@mui/material';
/**
 * Hides sharp corner of scroller behind it.
 */
export const HideScrollerEdgeContainer = styled('div')({
    height: '100%',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
});
