import { svgIconClasses } from '@mui/material';
export const MuiTabs = {
    styleOverrides: {
        root: {},
    },
};
export const MuiTab = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            minHeight: '48px',
            height: '48px',
            // Both border and borderRadius is used for :focus-visible outline only.
            border: '1px solid transparent',
            borderRadius: '10px',
            [`.${svgIconClasses.root}`]: {
                width: '.9375rem',
                height: '.9375rem',
            },
            ':not(.Mui-selected)': {
                [`.${svgIconClasses.root}`]: {
                    color: theme.palette.text.muted,
                },
            },
            '&:hover': {
                borderWidth: '1px',
            },
            '&.Mui-focusVisible': {
                outlineColor: ownerState
                    ? ownerState.color === 'primary'
                        ? theme.palette.primary.dark
                        : ownerState.color === 'secondary'
                            ? theme.palette.secondary.dark
                            : ownerState.color === 'success'
                                ? theme.palette.success.dark
                                : ownerState.color === 'error'
                                    ? theme.palette.error.dark
                                    : ownerState.color === 'info'
                                        ? theme.palette.info.dark
                                        : ownerState.color === 'warning'
                                            ? theme.palette.warning.dark
                                            : theme.palette.primary.dark
                    : theme.palette.primary.dark,
                outlineOffset: '-6px',
            },
        }),
    },
};
export const MuiTabPanel = {
    styleOverrides: {
        root: {
            padding: 0,
        },
    },
};
