import { jsx as _jsx } from "react/jsx-runtime";
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { useMatches } from 'react-router-dom';
const Breadcrumbs = () => {
    const matches = useMatches();
    // Get rid of any matches that don't have handle and crumb.
    // Map them into an array of elements, passing the loader data along with url parameters to each crumb.
    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb({ loaderData: match.data, params: matches[0].params }));
    return (_jsx(MuiBreadcrumbs, { separator: _jsx(BreadcrumbSeparator, {}), children: crumbs.map((crumb, index) => (_jsx(crumb.type, { ...crumb.props }, index))) }));
};
export const BreadcrumbSeparator = () => {
    return (_jsx("svg", { fill: "currentColor", viewBox: "0 0 20 20", "aria-hidden": "true", children: _jsx("path", { d: "M5.555 17.776l8-16 .894.448-8 16-.894-.448z" }) }));
};
export { Breadcrumbs };
