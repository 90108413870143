// Check if lodash's isEqual can be used.
// !IMPORTANT: Check before if it supports arrayEqual for [1,2] and [2,1], in our case these must be equal.
export const orderFilterEqual = (first, second) => {
    function arraysEqual(array1, array2) {
        if (!array1 && !array2)
            return true;
        if ((!array1 && array2) || (array1 && !array2))
            return false;
        if (!array1 || !array2)
            return false;
        return array1.length === array2.length && array1.every((item) => array2.includes(item));
    }
    if (!first && !second)
        return true;
    if ((!first && second) || (first && !second))
        return false;
    if (!first || !second)
        return false;
    return (arraysEqual(first.articles, second.articles) &&
        arraysEqual(first.brands, second.brands) &&
        arraysEqual(first.logos, second.logos) &&
        arraysEqual(first.statuses, second.statuses) &&
        arraysEqual(first.customers, second.customers) &&
        arraysEqual(first.suppliers, second.suppliers) &&
        arraysEqual(first.customerManagers, second.customerManagers) &&
        arraysEqual(first.supplierManagers, second.supplierManagers) &&
        arraysEqual(first.orders, second.orders) &&
        first.startPrice === second.startPrice &&
        first.endPrice === second.endPrice &&
        first.startQty === second.startQty &&
        first.endQty === second.endQty &&
        first.startDate === second.startDate &&
        first.endDate === second.endDate);
};
