import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, styled, tooltipClasses } from '@mui/material';
// This tsx is taken from official MUI docs.
export const SuccessTooltipStyled = styled(({ className, ...props }) => _jsx(Tooltip, { ...props, classes: { popper: className } }))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.success.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.success.background,
        borderColor: theme.palette.success.lighter200,
        paddingTop: '1px',
        paddingBottom: '1px',
        borderStyle: 'solid',
        borderWidth: '1px',
        opacity: 0.95,
    },
}));
export const SuccessTooltipTitleInnerContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    color: theme.palette.success.dark,
    fontSize: theme.typography.caption.fontSize,
}));
