export const MuiBackdrop = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            position: 'absolute',
            color: theme.palette.common.white,
            // TODO: to delete later, since now CircularProgressBackdrop component is available
            // borderRadius: `${theme.shape.borderRadius}px`,
            zIndex: theme.zIndex.drawer + 1,
        }),
    },
};
