export const claims = [
    'Manager',
    'Settings',
    'Warehouse',
    'PointOfIssuing',
    'CustomerManager',
    'CustomerHeadManager',
    'SupplierManager',
    'SupplierHeadManager',
];
